import React from 'react';
import {
    explisoftService,
    error as errorToast,
    success,
    Item,
    ItemRequest,
    ItemStock,
    ReceiveSupplyRequest,
    PurchaseReceiptBasic
} from '@services';
import _ from 'lodash';

export const useItemManagement = (query: string) => {
    const [items, setItems] = React.useState<Item[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');
    const [pageNo, setPageNo] = React.useState(1);
    const [hasMore, setHasMore] = React.useState(true);

    const loadMore = () => {
        getItems(query, pageNo);
    };

    const refresh = () => {
        getItems(query);
    };

    const getItems = React.useCallback(async (query: string, pageNo = 1) => {
        setLoading(true);
        setError('');
        return explisoftService
            .getItems(query, pageNo)
            .then((itemsReceived) => {
                if (pageNo === 1) {
                    setItems(itemsReceived.items);
                } else {
                    setItems((items) => [...items, ...itemsReceived.items]);
                }
                setLoading(false);
                setHasMore(itemsReceived.hasMore);
                setPageNo(pageNo + 1);
                return itemsReceived;
            })
            .catch((error) => {
                errorToast(error.message || 'Error getting items');
                setError(error.message);
                setLoading(false);
                throw error;
            });
    }, []);

    React.useEffect(() => {
        getItems(query);
    }, [query, getItems]);

    const createItem = async (itemRequest: ItemRequest) => {
        setLoading(true);
        setError('');
        return explisoftService
            .createItem(itemRequest)
            .then((item) => {
                getItems('');
                setLoading(false);
                success('Item created successfully');
                return item;
            })
            .catch((error) => {
                errorToast(error.message || 'Error creating item');
                setError(error.message);
                setLoading(false);
                throw error;
            });
    };

    const updateItem = async (item: Item) => {
        setLoading(true);
        setError('');
        return explisoftService
            .updateItem(item.sku, item)
            .then((item) => {
                const index = items.findIndex((i) => i.sku === item.sku);
                if (index !== -1) {
                    items[index] = item;
                    setItems([...items]);
                }
                setLoading(false);
                success('Item updated successfully');
                return item;
            })
            .catch((error) => {
                errorToast(error.message || 'Error updating item');
                setError(error.message);
                setLoading(false);
                throw error;
            });
    };

    const enableDisableItem = async (sku: number, state: boolean) => {
        setLoading(true);
        setError('');
        return explisoftService
            .enableDisableItem(sku, state)
            .then((item) => {
                const index = items.findIndex((i) => i.sku === item.sku);
                if (index !== -1) {
                    items[index] = item;
                    setItems([...items]);
                }
                setLoading(false);
                success('Item updated successfully');
                return item;
            })
            .catch((error) => {
                errorToast(error.message || 'Error updating item');
                setError(error.message);
                setLoading(false);
                throw error;
            });
    };

    return { items, loading, error, getItems, createItem, loadMore, hasMore, refresh, updateItem, enableDisableItem };
};

export const useServiceManagement = () => {};

export const useStockManagement = (locationId: string, query: string) => {
    const [items, setItems] = React.useState<ItemStock[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');
    const [pageNo, setPageNo] = React.useState(1);
    const [hasMore, setHasMore] = React.useState(false);

    const loadMore = () => {
        getItemsStock(locationId, query, pageNo);
    };

    const refresh = () => {
        getItemsStock(locationId, query);
    };

    const getItemsStock = React.useCallback(async (locationId: string, query: string, pageNo = 1) => {
        if (!locationId) return;
        setLoading(true);
        setError('');
        return explisoftService
            .getAllItemsStockByLocation(locationId, query, pageNo)
            .then((itemsReceived) => {
                if (pageNo === 1) {
                    setItems(itemsReceived.items);
                } else {
                    setItems((items) => [...items, ...itemsReceived.items]);
                }
                setLoading(false);
                setHasMore(itemsReceived.hasMore);
                setPageNo(pageNo + 1);
                return itemsReceived;
            })
            .catch((error) => {
                errorToast(error.message || 'Error getting items');
                setError(error.message);
                setLoading(false);
                throw error;
            });
    }, []);

    React.useEffect(() => {
        getItemsStock(locationId, query);
    }, [query, locationId, getItemsStock]);

    const correctItemStock = async (sku: string, quantity: number, reason: string) => {
        setLoading(true);
        setError('');
        return explisoftService
            .correctStockByLocation({
                sku: parseInt(sku),
                quantity,
                locationId,
                reason
            })
            .then((item) => {
                const index = items.findIndex((i) => i.sku === item.sku);
                if (index !== -1) {
                    items[index] = item;
                    setItems([...items]);
                }
                setLoading(false);
                success('Item updated successfully');
                return item;
            })
            .catch((error) => {
                errorToast(error.message || 'Error updating item');
                setError(error.message);
                setLoading(false);
                throw error;
            });
    };

    const moveStock = async (sku: string, quantity: number, reason: string, toLocationId: string) => {
        setLoading(true);
        setError('');
        return explisoftService
            .moveStock({
                sku: parseInt(sku),
                quantity,
                fromLocationId: locationId,
                reason,
                toLocationId
            })
            .then((item) => {
                console.log(item);
                const index = items.findIndex((i) => i.sku === item.sku);
                if (index !== -1) {
                    items[index] = {
                        ...items[index],
                        quantity: item.quantity
                    };
                    setItems([...items]);
                }
                setLoading(false);
                success('Item updated successfully');
                return item;
            })
            .catch((error) => {
                errorToast(error.message || 'Error moving stock');
                setError(error.message);
                setLoading(false);
                throw error;
            });
    };

    const receiveSupply = React.useCallback(
        async (receiveSupplyRequest: ReceiveSupplyRequest) => {
            setLoading(true);
            setError('');
            return explisoftService
                .receiveSupply(receiveSupplyRequest)
                .then((receivedSupply) => {
                    receivedSupply.items.forEach((item) => {
                        const index = items.findIndex((i) => i.sku === item.item.sku);
                        if (index !== -1) {
                            items[index] = {
                                ...items[index],
                                quantity: item.quantity + items[index].quantity
                            };
                        }
                    });
                    setLoading(false);
                    success('Item updated successfully');
                    return receivedSupply;
                })
                .catch((error) => {
                    errorToast(error.message || 'Error moving stock');
                    setError(error.message);
                    setLoading(false);
                    throw error;
                });
        },
        [items]
    );

    return {
        items,
        loading,
        error,
        hasMore,
        loadMore,
        refresh,
        correctItemStock,
        moveStock,
        receiveSupply
    };
};

export const usePurchaseHistory = (params?: { locationId?: string; supplierId?: string }) => {
    const [items, setItems] = React.useState<PurchaseReceiptBasic[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');
    const [pageNo, setPageNo] = React.useState(1);
    const [hasMore, setHasMore] = React.useState(false);

    React.useEffect(() => {
        setPageNo(1);
    }, [params?.locationId, params?.supplierId]);

    const loadMore = () => {
        getItemsStock(params?.locationId, params?.supplierId, pageNo);
    };

    const refresh = () => {
        getItemsStock(params?.locationId, params?.supplierId);
    };

    const getItemsStock = React.useCallback(async (locationId: string, supplierId: string, pageNo = 1) => {
        if (!locationId && !supplierId) return;
        setLoading(true);
        setError('');
        return explisoftService
            .getAllPurchaseReceipts({ locationId, supplierId }, pageNo)
            .then((itemsReceived) => {
                if (pageNo === 1) {
                    setItems(itemsReceived.items);
                } else {
                    setItems((items) => [...items, ...itemsReceived.items]);
                }
                setLoading(false);
                setHasMore(itemsReceived.hasMore);
                setPageNo(pageNo + 1);
                return itemsReceived;
            })
            .catch((error) => {
                errorToast(error.message || 'Error getting items');
                setError(error.message);
                setLoading(false);
                throw error;
            });
    }, []);

    const getPurchaseReceipt = React.useCallback(async (purchaseReceiptId: string) => {
        setLoading(true);
        setError('');
        return explisoftService
            .getPurchaseReceipt(purchaseReceiptId)
            .then((purchaseReceipt) => {
                setLoading(false);
                return {
                    ...purchaseReceipt,
                    invoiceDate: purchaseReceipt.invoiceDate ? new Date(purchaseReceipt.invoiceDate) : undefined
                };
            })
            .catch((error) => {
                errorToast(error.message || 'Error getting purchase receipt');
                setError(error.message);
                setLoading(false);
                throw error;
            });
    }, []);

    return {
        items,
        loading,
        error,
        hasMore,
        loadMore,
        refresh,
        getPurchaseReceipt
    };
};
