import { IconButton, TextField } from '@mui/material';
import { Config, error, explisoftService, success } from '@services';
import { IconDeviceFloppy } from '@tabler/icons';
import * as React from 'react';
import Wait from '../Wait';
import { usePermission } from '@hooks';

export const ConfigBox = (props: { app: string; configKey: string; lable?: string }) => {
    const [config, setConfig] = React.useState<Config | null>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const { isReadConfigAllowed, isUpdateConfigAllowed } = usePermission();

    const handleConfigChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setConfig({ app: props.app, key: props.configKey, value });
    };

    React.useEffect(() => {
        if (!isReadConfigAllowed) {
            setConfig(null);
            return;
        }
        setLoading(true);
        explisoftService
            .getConfig(props.app, props.configKey)
            .then((config) => {
                setConfig(config);
                setLoading(false);
            })
            .catch(() => {
                setConfig(null);
                setLoading(false);
                error('Error in getting config');
            });
    }, [props.app, props.configKey, isReadConfigAllowed]);

    const handleSaveConfig = async () => {
        if (!config) return;
        setLoading(true);
        explisoftService
            .setConfig(config.app, config.key, config.value)
            .then(() => {
                setLoading(false);
                success('Config saved');
            })
            .catch(() => {
                error('Error saving config');
                setLoading(false);
            });
    };

    return (
        <div style={{ margin: '10px' }}>
            <TextField
                label={`${props.lable || (config?.value ? config?.key : props.configKey) + 'config value'}`}
                variant="outlined"
                style={{ width: '20rem', marginRight: '1rem' }}
                value={isReadConfigAllowed ? config?.value || '' : 'You are not allowed to see config'}
                onChange={handleConfigChange}
                disabled={!isUpdateConfigAllowed}
            />
            <Wait loading={loading} />
            {!loading && isUpdateConfigAllowed && (
                <IconButton onClick={handleSaveConfig}>
                    <IconDeviceFloppy />
                </IconButton>
            )}
        </div>
    );
};
