import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { IconDeviceFloppy, IconX } from '@tabler/icons';
import { EMIInstance, error, explisoftService, success } from '@services';

export const ReceiveEMIPaymentDialog = (props: { emiInstance: EMIInstance; onClose: () => void; onSave: () => void }) => {
    const [emiAmount, setEmiAmount] = React.useState(props.emiInstance.emiAmount - props.emiInstance.emiAmountPaid);
    const [emiDate, setEmiDate] = React.useState(props.emiInstance.emiDate);
    const [paymentMode, setPaymentMode] = React.useState('Cash');
    const [paymentReference, setPaymentReference] = React.useState('');

    const [saving, setSaving] = React.useState(false);
    const handleSave = React.useCallback(() => {
        setSaving(true);
        explisoftService
            .receiveEMIPayment(props.emiInstance.emiScheduleId, emiAmount, emiDate, paymentMode, paymentReference)
            .then((res) => {
                success(res.message);
                props.onSave();
            })
            .catch((err) => {
                error(err.message.toString() || 'Something went wrong');
            })
            .finally(() => {
                setSaving(false);
            });
    }, [props, emiAmount, emiDate, paymentMode, paymentReference]);
    return (
        <Dialog fullWidth maxWidth={'sm'} open={!!props.emiInstance} onClose={props.onClose}>
            <DialogTitle>
                <h3>Receive EMI Payment</h3>
            </DialogTitle>
            <DialogContent>
                <TextField
                    label="EMI Amount"
                    value={emiAmount}
                    fullWidth
                    type="number"
                    style={{ marginTop: '10px' }}
                    onChange={(e) => setEmiAmount(parseFloat(e.target.value))}
                />
                <TextField
                    label="EMI Date"
                    value={emiDate.toISOString().split('T')[0]}
                    fullWidth
                    type="date"
                    style={{ marginTop: '10px' }}
                    onChange={(e) => setEmiDate(new Date(e.target.value))}
                />
                <TextField
                    label="Payment Mode"
                    value={paymentMode}
                    fullWidth
                    style={{ marginTop: '10px' }}
                    onChange={(e) => setPaymentMode(e.target.value)}
                />
                <TextField
                    label="Payment Reference"
                    value={paymentReference}
                    fullWidth
                    style={{ marginTop: '10px' }}
                    onChange={(e) => setPaymentReference(e.target.value)}
                />
            </DialogContent>

            <DialogActions sx={{ marginTop: '0px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', padding: '10px' }}>
                    <Button disabled={saving} onClick={props.onClose} variant="outlined">
                        <IconX /> Cancel
                    </Button>
                    <Button disabled={saving} onClick={handleSave} variant="contained" style={{ marginLeft: '5px' }}>
                        <IconDeviceFloppy /> Save
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};
