import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// project imports
import MainLayout from '../layout/MainLayout';
import { Loadable } from '@components';
import AppJson from '../views/apps/list.json';
import { SubApps } from '../views/sub-apps';
// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));

const appsRender = [];
for (const app of AppJson.apps) {
    const AppPage = Loadable(lazy(() => import(`../views/apps/${app.name}/`)));
    appsRender.push({
        path: 'apps',
        children: [
            {
                path: `${app.name}`,
                element: <AppPage />
            }
        ]
    });
}

const subAppsRender = [];

for (const subApp of SubApps) {
    const params = subApp.params;
    const pathParams = params.map((param) => `${param}/:${param}`).join('/');

    subAppsRender.push({
        path: 'sub-apps',
        children: [
            {
                path: `${subApp.name}/${pathParams}`,
                element: <subApp.app />
            }
        ]
    });
}

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = (isLoggedIn = false) => ({
    path: '/',
    element: isLoggedIn ? <MainLayout complete={true} /> : <Navigate to="/auth/login" />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        ...subAppsRender,
        ...appsRender
    ]
});

export default MainRoutes;
