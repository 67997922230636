/**
 * IMPORTANT: DO NOT MODIFY THIS FILE MANUALLY
 */

import * as purchaseReceiptDetails from './purchase-receipt-details';
import * as orderDetails from './orderDetails';
import * as textImageToVideoDetails from './textImageToVideoDetails';
/** DO NOT DELETE THIS LINE, IMPORT SUBAPP HERE **/

const subApps = [
    orderDetails,
    textImageToVideoDetails,
    /** DO NOT DELETE THIS LINE, APPEND SUBAPP HERE **/
    purchaseReceiptDetails
];

export const SubApps = [];

for (const subApp of subApps) {
    SubApps.push({
        name: subApp.name,
        app: subApp.App,
        params: subApp.params
    });
}

const subAppsRoutes = {
    /** DO NOT DELETE THIS LINE, APPEND SUBAPP ROUTE HERE **/
    textImageToVideoDetails: textImageToVideoDetails.open,
    purchaseReceiptDetails: purchaseReceiptDetails.open,
    orderDetails: orderDetails.open
};

export default subAppsRoutes;
