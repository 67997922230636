import * as React from 'react';
import { explisoftService, Item, error as errorToast } from '@services';
import { IBasePickerProps, BasePicker, Data } from '../base-picker';
import { skuFormatter } from '@utils';

const getLabel = (option: Item) =>
    `${skuFormatter(option.sku)} ${option.name ?? ''}, ${option.description ?? ''}, ${option.hsnCode ?? ''}, ${option.brand ?? ''}, ${
        option.manufacturer ?? ''
    }`;
const getData = (query?: string) =>
    explisoftService
        .getItems(query)
        .then((data) => data.items.map((d) => ({ ...d, id: d.sku.toString() })))
        .catch((err) => {
            errorToast(err.message || 'Failed to load suppliers');
            return [];
        });

interface OtherInformation {
    index: number;
    onSelect: (selectedItems: Item[] | Item, index: number) => void;
}
type ITenantPickerProps = Pick<
    IBasePickerProps<Item & Data>,
    Exclude<keyof IBasePickerProps<Item & Data>, 'getLabel' | 'getData' | 'getObjectId' | 'onSelect'>
> &
    OtherInformation;

export function ItemPicker({
    multiple = false,
    id = 'supplier-picker',
    key,
    style = {},
    onSelect,
    label,
    initalValue = [],
    index
}: ITenantPickerProps) {
    const handleOnSelect = React.useCallback(
        (selectedItems: Item[] | Item) => {
            onSelect(selectedItems, index);
        },
        [onSelect, index]
    );
    return (
        <BasePicker<Item & Data>
            multiple={multiple}
            id={id}
            key={key}
            style={style}
            onSelect={handleOnSelect}
            label={label}
            initalValue={initalValue}
            getLabel={getLabel}
            getData={getData}
        />
    );
}
