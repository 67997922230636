import * as React from 'react';
import { explisoftService, Designation } from '@services';
import { IBasePickerProps, BasePicker, Data } from '../base-picker';

const getLabel = (option: Designation) => `${option.name} (${option.description})`;

export type IDesignationPickerProps = Pick<
    IBasePickerProps<Designation & Data>,
    Exclude<keyof IBasePickerProps<Designation & Data>, 'getLabel' | 'getData' | 'getObjectId'>
>;

export const DesignationPicker = ({
    multiple = false,
    id = 'DesignationPicker',
    key,
    style = {},
    onSelect,
    label,
    initalValue = []
}: IDesignationPickerProps) => {
    const [designations, setDesignations] = React.useState<Designation[]>([]);
    React.useEffect(() => {
        explisoftService.getDesignations().then((data) => {
            setDesignations(data);
        });
    }, []);

    const getData = (query?: string) =>
        Promise.resolve(
            designations
                .filter(
                    (option) =>
                        option.name.toLowerCase().includes(query.toLowerCase()) ||
                        option.description.toLowerCase().includes(query.toLowerCase())
                )
                .map((option) => ({ ...option, id: option.designationId }))
        );

    return (
        <BasePicker<Designation & Data>
            multiple={multiple}
            id={id}
            key={key}
            style={style}
            onSelect={onSelect}
            label={label}
            initalValue={initalValue}
            getLabel={getLabel}
            getData={getData}
        />
    );
};
