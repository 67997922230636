import * as React from 'react';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from 'lodash';
import { Paper } from '@mui/material';

const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} style={{ background: '#DDD' }} />;
};
export interface Data {
    id: string;
}
export interface IBasePickerProps<T extends Data> {
    id: string;
    key?: string;
    multiple?: boolean;
    style?: React.CSSProperties;
    label: string;
    onSelect?: (t: T[] | T | null) => void;
    initalValue?: T[] | T | null;
    getLabel: (option: T) => string;
    getData: (query?: string) => Promise<T[]>;
}

const getSingleValue = <T extends object>(value: T | T[]): T => {
    return Array.isArray(value) ? value[0] : value;
};

const getArraryValue = <T extends object>(value: T | T[]): T[] => {
    if (!value) {
        return [];
    }
    return Array.isArray(value) ? value : [value];
};

const getValue = <T extends object>(multiple: boolean, value: T | T[]) => {
    return multiple ? getArraryValue(value) : getSingleValue(value);
};

export const BasePicker = <T extends Data>({
    multiple = false,
    id = '',
    key,
    style = {},
    onSelect,
    label,
    initalValue = multiple ? [] : null,
    getLabel,
    getData
}: IBasePickerProps<T>) => {
    const [value, setValue] = React.useState<T[] | T | null>(initalValue);
    const [options, setOptions] = React.useState<T[]>([]);

    const handleQueryChange = debounce((e: any) => {
        const query = e.target.value;
        getData(query).then((networkOptions) => {
            setOptions(networkOptions);
        });
    }, 500);

    React.useEffect(() => {
        getData('').then((networkOptions) => {
            setOptions(networkOptions);
        });
    }, [getData]);

    React.useEffect(() => {
        onSelect?.(getValue(multiple, value));
    }, [value, multiple, onSelect]);

    React.useEffect(() => {
        return () => handleQueryChange.cancel();
    }, [handleQueryChange]);

    return multiple ? (
        <Autocomplete
            multiple={true}
            id={id}
            key={key}
            value={getArraryValue(value)}
            onChange={(_, newValue) => {
                setValue(newValue ? newValue : []);
            }}
            sx={{ color: '#000' }}
            onKeyUp={handleQueryChange}
            options={options}
            getOptionLabel={(option: T) => (!Array.isArray(option) ? getLabel(option) : '')}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option: T, index) => <Chip key={option.id} label={getLabel(option)} {...getTagProps({ index })} />)
            }
            PaperComponent={CustomPaper}
            style={{ borderColor: '#000', ...style }}
            renderInput={(params) => <TextField {...params} label={label} placeholder={label} autoComplete="off" />}
        />
    ) : (
        <Autocomplete
            multiple={false}
            id={id}
            key={key}
            value={getSingleValue(value)}
            onChange={(_, newValue) => {
                setValue(newValue ? newValue : []);
            }}
            sx={{ color: '#000' }}
            onKeyUp={handleQueryChange}
            options={options}
            getOptionLabel={(option: T) => (!Array.isArray(option) ? getLabel(option) : '')}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option: T, index) => <Chip key={option.id} label={getLabel(option)} {...getTagProps({ index })} />)
            }
            PaperComponent={CustomPaper}
            style={{ borderColor: '#000', ...style }}
            renderInput={(params) => <TextField {...params} label={label} placeholder={label} autoComplete="off" />}
        />
    );
};
