import React, { useState, useRef, useEffect, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import { MainCard, Transitions, Avatar } from '@components';

// assets
import { IconLogout } from '@tabler/icons';
import { ServiceContext } from '@controllers';
import { Key, MyInfo, ToastMessage, cache } from '@services';

// ==============================|| TOAST ||============================== //
import { ToastContainer, toast, ToastPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastOptions = {
    position: 'top-right' as ToastPosition,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true
};
// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { authController } = useContext(ServiceContext);

    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    const handleLogout = async () => {
        authController?.logout();
        navigate('/');
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    const [me, setMe] = useState({} as MyInfo);

    useEffect(() => {
        authController?.me().then((myInfo) => {
            setMe(myInfo);
        });
    }, [authController]);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current?.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        const unsub = cache.addListener(Key.TOAST, () => {
            const toastMessage = cache.get<ToastMessage>(Key.TOAST);
            if (!toastMessage || !toastMessage.type || !toastMessage.message) return;
            switch (toastMessage.type) {
                case 'success':
                    toast.success(toastMessage.message, ToastOptions);
                    break;
                case 'error':
                    toast.error(toastMessage.message, {
                        ...ToastOptions,
                        autoClose: 15000
                    });
                    break;
                case 'info':
                    toast.info(toastMessage.message, ToastOptions);
                    break;
                case 'warn':
                    toast.warn(toastMessage.message, ToastOptions);
                    break;
                default:
                    toast.info(toastMessage.message, ToastOptions);
                    break;
            }
        });
        return () => {
            unsub();
        };
    }, []);

    return (
        <>
            <ToastContainer />
            <Avatar firstName={me.firstName} lastName={me.lastName} onClick={handleToggle} anchorRef={anchorRef} />

            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    /* @ts-ignore */
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                {/* @ts-ignore */}
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ p: 2 }}>
                                        <Stack>
                                            <Stack direction="row" alignItems="center">
                                                <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                    {me.firstName} {me.lastName}
                                                </Typography>
                                            </Stack>
                                            <Typography variant="subtitle2">{me.domainName}</Typography>
                                        </Stack>
                                        <Divider />
                                    </Box>
                                    <PerfectScrollbar style={{ height: '100%', overflowX: 'hidden' }}>
                                        <Box>
                                            <List
                                                sx={{
                                                    maxWidth: 350,
                                                    minWidth: 200
                                                }}
                                            >
                                                <ListItemButton onClick={handleLogout}>
                                                    <ListItemIcon>
                                                        <IconLogout size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                    </PerfectScrollbar>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
