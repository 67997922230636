import { useRoutes } from 'react-router-dom';
import { useContext } from 'react';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import OtherAuthenticationRoutes from './OtherAuthenticationRoutes';
import { useSearchParams } from 'react-router-dom';

import { ServiceContext } from '../controllers';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { authController } = useContext(ServiceContext);
    const isLoggedIn = authController.isAuthenticated();
    const [searchParams] = useSearchParams();
    return useRoutes([MainRoutes(isLoggedIn), AuthenticationRoutes(isLoggedIn, searchParams.toString()), OtherAuthenticationRoutes()]);
}
