import React from 'react';
import Paper from '@mui/material/Paper';
import { default as MuiTable } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IconEdit, IconDeviceFloppy, IconX, IconTrash, IconPlus } from '@tabler/icons';
import { IconButton, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import { ToggleButton } from '../toggle/toggle-button';

export interface ITableProps<T> {
    displayColumn: {
        property: keyof T;
        header: string;
        editable?: boolean;
        formatter?: (value: any) => string;
    }[];
    onSave?: (updatedData: T) => Promise<any>;
    onDelete?: (deletedData: T) => Promise<any>;
    onEnableDisable?: {
        onStateChange: (data: T, newState: boolean) => void;
        stateProperty: keyof T;
    };
    onClick?: (clickedData: T) => void;
    data: T[];
    idProperty: keyof T;
    onSaveNew?: (newData: Partial<T>) => Promise<any>;
    actions?: {
        id: string;
        label: string;
        icon: React.ReactNode;
    }[];
    onActionClick?: (actionId: string, data: T) => void;
}

export const Table = <T extends Object>(props: ITableProps<T>) => {
    const [editableRow, setEditableRow] = React.useState<T | undefined>(undefined);
    const handleEdit = React.useCallback((row: T) => {
        setEditableRow(row);
    }, []);
    const updateEditableRow = React.useCallback(
        (key: keyof T, value: string) => {
            if (editableRow) {
                setEditableRow((prev) => ({ ...prev, [key]: value }));
            }
        },
        [editableRow]
    );

    const [rows, setRows] = React.useState<T[]>([]);

    React.useEffect(() => {
        setRows(props.data);
    }, [props.data]);

    const [newRow, setNewRow] = React.useState<T | undefined>(undefined);
    const onAddNewRow = React.useCallback(() => {
        setNewRow({} as T);
    }, []);
    const updateNewRow = React.useCallback(
        (key: keyof T, value: string) => {
            if (newRow) {
                setNewRow((prev) => ({ ...prev, [key]: value }));
            }
        },
        [newRow]
    );

    return (
        <TableContainer component={Paper}>
            {props.onSaveNew && (
                <Toolbar>
                    <Typography style={{ flex: 1 }}></Typography>
                    <IconButton onClick={onAddNewRow}>
                        <IconPlus />
                    </IconButton>
                </Toolbar>
            )}
            <MuiTable sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {props.displayColumn.map((column) => {
                            return <TableCell key={column.property as string}>{column.header}</TableCell>;
                        })}
                        {props.onSave && <TableCell>Edit</TableCell>}
                        {props.onDelete && <TableCell>Delete</TableCell>}
                        {props.onEnableDisable && <TableCell>Enable/Disable</TableCell>}
                        {props.actions && props.actions.length > 0 && <TableCell>Actions</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.length === 0 && !newRow && (
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell colSpan={props.displayColumn.length + 3}>No data</TableCell>
                        </TableRow>
                    )}
                    {newRow && (
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            {props.displayColumn.map((column) => {
                                return (
                                    <TableCell key={column.property as string}>
                                        <TextField
                                            label={column.header}
                                            variant="outlined"
                                            value={newRow[column.property as string]}
                                            onChange={(event) => updateNewRow(column.property, event.target.value)}
                                        />
                                    </TableCell>
                                );
                            })}
                            {props.actions && props.actions.length > 0 && <TableCell></TableCell>}
                            <TableCell>
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        props.onSaveNew(newRow).then(() => {
                                            setNewRow(undefined);
                                        });
                                    }}
                                >
                                    <IconDeviceFloppy />
                                </IconButton>
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setNewRow(undefined);
                                    }}
                                >
                                    <IconX />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )}
                    {rows.map((row) => {
                        return row[props.idProperty] !== editableRow?.[props.idProperty] ? (
                            <TableRow
                                key={row[props.idProperty] as string}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                hover={!!props.onClick}
                                onClick={props.onClick ? () => props.onClick(row) : undefined}
                            >
                                {props.displayColumn.map((column) => {
                                    return (
                                        <TableCell key={column.property as string}>
                                            {column.formatter
                                                ? column.formatter(row[column.property as string])
                                                : row[column.property as string]}
                                        </TableCell>
                                    );
                                })}
                                {props.onSave && (
                                    <TableCell>
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleEdit(row);
                                            }}
                                        >
                                            <IconEdit />
                                        </IconButton>
                                    </TableCell>
                                )}
                                {props.onDelete && (
                                    <TableCell>
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                props.onDelete(row);
                                            }}
                                        >
                                            <IconTrash />
                                        </IconButton>
                                    </TableCell>
                                )}
                                {props.onEnableDisable && (
                                    <TableCell>
                                        <ToggleButton
                                            value={row[props.onEnableDisable.stateProperty as string]}
                                            onChange={(value, e) => {
                                                e.stopPropagation();
                                                props.onEnableDisable.onStateChange(row, value);
                                            }}
                                        />
                                    </TableCell>
                                )}
                                {props.actions && props.actions.length > 0 && (
                                    <TableCell>
                                        {props.actions.map((action) => {
                                            return (
                                                <Tooltip key={action.id} title={action.label}>
                                                    <IconButton
                                                        key={action.id}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            props.onActionClick?.(action.id, row);
                                                        }}
                                                    >
                                                        {action.icon}
                                                    </IconButton>
                                                </Tooltip>
                                            );
                                        })}
                                    </TableCell>
                                )}
                            </TableRow>
                        ) : (
                            <TableRow
                                key={row[props.idProperty] as string}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                hover={!!props.onClick}
                                onClick={props.onClick ? () => props.onClick(row) : undefined}
                            >
                                {props.displayColumn.map((column) => {
                                    return (
                                        <TableCell key={column.property as string}>
                                            {column.editable ? (
                                                <TextField
                                                    label={column.header}
                                                    variant="outlined"
                                                    type={typeof row[column.property as string] === 'number' ? 'number' : 'text'}
                                                    value={editableRow[column.property as string]}
                                                    onChange={(event) => updateEditableRow(column.property, event.target.value)}
                                                />
                                            ) : (
                                                row[column.property as string]
                                            )}
                                        </TableCell>
                                    );
                                })}
                                {props.onSave && (
                                    <TableCell>
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                props.onSave(editableRow).then(() => {
                                                    setEditableRow(undefined);
                                                });
                                            }}
                                        >
                                            <IconDeviceFloppy />
                                        </IconButton>
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setEditableRow(undefined);
                                            }}
                                        >
                                            <IconX />
                                        </IconButton>
                                    </TableCell>
                                )}
                                {props.onDelete && (
                                    <TableCell>
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                props.onDelete(row);
                                            }}
                                        >
                                            <IconTrash />
                                        </IconButton>
                                    </TableCell>
                                )}
                                {props.onEnableDisable && (
                                    <TableCell>
                                        <ToggleButton
                                            value={row[props.onEnableDisable.stateProperty as string]}
                                            onChange={(value, e) => {
                                                e.stopPropagation();
                                                props.onEnableDisable.onStateChange(row, value);
                                            }}
                                        />
                                    </TableCell>
                                )}
                                {props.actions && props.actions.length > 0 && (
                                    <TableCell>
                                        {props.actions.map((action) => {
                                            return (
                                                <Tooltip key={action.id} title={action.label}>
                                                    <IconButton
                                                        key={action.id}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            props.onActionClick?.(action.id, row);
                                                        }}
                                                    >
                                                        {action.icon}
                                                    </IconButton>
                                                </Tooltip>
                                            );
                                        })}
                                    </TableCell>
                                )}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </MuiTable>
        </TableContainer>
    );
};
