import { Policy } from '../../controllers';

export type { Policy };
export interface PolicyValidator {
    validate(policy: Policy): boolean;
}

export const checkLicense = (policy: Policy, license: string): boolean => {
    return policy?.licenseAndScope?.[license] !== undefined;
};
