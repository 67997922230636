import { TextField, Typography } from '@mui/material';
import { ProjectContent } from '@services';
import React from 'react';
import { DeleteWithConfirmation } from '@components';
import { ImageLoader } from './image-loader';
import { ToWords } from 'to-words';
import extractor from "extract-numbers";

const hindiSupportedChars = ' !,-.:;? ँंःअआइईउऊऋएऐऑओऔकखगघङचछजझञटठडढणतथदधनपफबभमयरऱलळवशषसह़ािीुूृॅेैॉोौ्क़ख़ग़ज़ड़ढ़फ़ॠ‍‎–';
const englishSupportedChars = " !&,-.:;?ABCDEFGHIJKLMNOPQRSTUVWYZ`abcdefghijklmnopqrstuvwxyz\u00e2\u02dc\u2013\u2014\u20ac\u2122";

const toHindiWords = new ToWords({ localeCode: 'hi-IN', });
const toEnglishWords = new ToWords({ localeCode: 'en-IN', });

const toNumber = (num: string, lang: 'hi' | 'en') => {
    const converter = lang === 'hi' ? toHindiWords : toEnglishWords;
    return converter.convert(parseFloat(num));
}

const detectLanguage = (text: string) => {
    const hindiChars = text.split('').filter(char => hindiSupportedChars.includes(char)).length;
    const englishChars = text.split('').filter(char => englishSupportedChars.includes(char)).length;
    return hindiChars > englishChars ? 'hi' : 'en';
}

const langugaeName = (lang: string) => {
    switch (lang) {
        case 'hi':
            return 'Hindi';
        case 'en':
            return 'English';
        default:
            return 'Unknown';
    }
}

const getErrorCorrectionText = (rawText: string) => {
    const lang = detectLanguage(rawText);
    const supportedChars = lang === 'hi' ? hindiSupportedChars : englishSupportedChars;
    const text = rawText.replaceAll('\n', ' ').replaceAll("<", "&lt").replaceAll(">", "&gt");
    // build a div with support chars are in black and not supported char in red
    let isError = false;
    let finalText = `<b>Text in red is unrecognized characters/words. Please replace it with correct ${langugaeName(lang)} word or remove it</b><br /><span>`;
    let lastColor = "none";
    for (let i = 0; i < text.length; i++) {
        const char = text[i];
        const currentColor = supportedChars.includes(char) ? "black" : "red";
        if (currentColor === 'red') {
            isError = true;
        }
        if (currentColor !== lastColor) {
            finalText += `</span><span style="color: ${currentColor}">`;
            lastColor = currentColor;
        }
        finalText += char;
    }
    finalText += "</span>";

    const possibleNumbersReplacements: { [key: string]: string[] } = {};
    const nums = extractor(text) as string[] || [];
    nums.forEach(num => {
        if (!possibleNumbersReplacements.hasOwnProperty(num)) {
            isError = true;
            const numValue = toNumber(num, lang);
            possibleNumbersReplacements[num] = [numValue];
            if (num.length > 1) {
                possibleNumbersReplacements[num].push(num.split('').map(n => {
                    if (n === '.') {
                        return lang === 'hi' ? 'दशमलव' : 'point';
                    } else if (n === '-') {
                        return lang === 'hi' ? 'माइनस' : 'minus';
                    } else {
                        return toNumber(n, lang);
                    }
                }).join(' '));
            }
        }
    });
    // build a div with number are in red and their possible replacement in black in bracket
    let isFirstNumberError = true;
    for (const num in possibleNumbersReplacements) {
        if (isFirstNumberError) {
            isFirstNumberError = false;
            finalText += '<br /><b>Please replace the number with following hindi text.</b>'
        }
        const numValue = possibleNumbersReplacements[num];
        finalText += `<br /><span style="color: red">${num}</span><span style="color: black">(${numValue.join(', ')})</span>`;
    }
    return { finalText, isError };
}


export const ContentCard = (props: {
    index: string,
    content: ProjectContent,
    onSave: (contentId: string, context: string) => Promise<void>,
    onDelete: (contentId: string) => void,
    onErrorStateChange: (contentId: string, isError: boolean) => void,
    onContentUpdate: (contentId: string, context: string) => void,
}) => {
    const { content, onSave, onErrorStateChange, onContentUpdate, onDelete } = props;
    const [textError, setTextError] = React.useState<boolean>(false);
    const [imageError, setImageError] = React.useState<boolean>(false);

    React.useEffect(() => {
        onErrorStateChange(content.textId, textError || imageError);
    }, [textError, imageError, onErrorStateChange, content.textId]);

    const [text, setText] = React.useState<string>(props.content.content);

    const renderErrorText = React.useCallback((text: string) => {
        if (!text) {
            setTextError(true);
            return;
        }
        const displayTextError = getErrorCorrectionText(text);
        setTextError(displayTextError.isError || text.trim() === '');
        document.getElementById(`${content.textId}-display-div`)!.innerHTML = displayTextError.isError ? displayTextError.finalText : '';
    }, [content.textId]);

    React.useEffect(() => {
        renderErrorText(content.content);
        onContentUpdate(content.textId, content.content || "");
    }, [renderErrorText, content.content, onContentUpdate, content.textId]);

    const [timer, setTimer] = React.useState<NodeJS.Timeout | null>(null);
    const [executedParam, setExecutedParam] = React.useState<string>(props.content.content);
    React.useEffect(() => {
        if (executedParam === text) {
            return;
        }
        if (timer) {
            clearTimeout(timer);
        }
        setExecutedParam(text);
        const timerId = setTimeout(() => {
            renderErrorText(text);
            onSave(content.textId, text);
            onContentUpdate(content.textId, text || "");
        }, 3000);
        setTimer(timerId);
    }, [text, onSave, content.textId, timer, executedParam, renderErrorText, onContentUpdate]);

    const handleDeleteConfirm = React.useCallback(() => {
        onDelete(content.textId);
    }, [onDelete, content.textId]);

    const handleTextUpdate = React.useCallback((e) => {
        const text = (e.target.value as string).replaceAll('।', '.');
        setText(text)
    }, []);

    const contentSize = React.useMemo(() => {
        return text?.length || 0;
    }, [text]);

    const onImageLoad = () => {
        setImageError(false);
    };

    const onImageLoadError = () => {
        setImageError(true);
    };

    return (
        <div>
            <div style={{ padding: '3px', display: 'flex', flexDirection: 'row', gap: '3px', border: '1px solid', margin: '4px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <TextField fullWidth value={text} onChange={handleTextUpdate} multiline rows={4} />
                    <Typography variant="caption" color={contentSize > 3000 ? 'error' : 'initial'}>{props.index}  {contentSize}/3000</Typography>
                    <div id={`${props.content.textId}-display-div`} ></div>
                </div>

                <ImageLoader onLoad={onImageLoad} onError={onImageLoadError} imageUrl={props.content.imageUrl} projectId={props.content.projectId} contentId={props.content.textId} />
                <DeleteWithConfirmation message="Are you sure, you want to delete this content?" onConfirm={handleDeleteConfirm} />
            </div>
        </div >
    )
}