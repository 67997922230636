import {
    ExplisoftService,
    AuthToken,
    EmailValidationResult,
    RegisterUserRequest,
    ApiGenericResponse,
    MyInfo,
    User,
    AuthorizationGroupDetails,
    AuthorizationGroup,
    License,
    Tenant,
    RegisterAdminUserFromPublicEmailDomain,
    AuthorizationGroupPublicDetails,
    JoinAuthGroupRequest,
    LicenseBasic,
    Department,
    Designation,
    LevelSuggestions,
    UserDetails,
    UserPatchParam,
    Location,
    LocationRequest,
    LocationUpdateRequest,
    Account,
    AccountRequest,
    AccountType,
    Contact,
    ContactRequest,
    Item,
    ItemRequest,
    ItemStock,
    QuantityCorrection,
    MoveStock,
    Stock,
    ReceiveSupply,
    ReceiveSupplyRequest,
    PurchaseReceiptBasic,
    Config,
    Api,
    Order,
    OrderRequest,
    OrderBasic,
    OrderFilter,
    Project,
    ProjectRequest,
    ProjectContent
} from './explisoft-service.interface';
import { cache, Key, Policy } from '@services';

const getHeader = (method: string, payload?: any) => {
    return {
        method: method || 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    };
};

export const execute = <T>(api: Api, payload?: any): Promise<T> => {
    return fetch(api.url, getHeader(api.method, payload))
        .then(async (response) => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson ? await response.json() : null;
            if (!response.ok) {
                return Promise.reject(data || response.statusText);
            }
            return data as T;
        })
        .catch((error: any) => {
            if (error?.errorCode === 'NOT_AUTHENTICATED') {
                window.location.href = '/auth/login';
            }
            throw error;
        });
};

const URL: { [key: string]: Api } = {
    LOGIN: {
        url: '/api/v1/login',
        method: 'POST'
    },
    VALIDAT_EMAIL: {
        url: '/api/v1/email/:emailId',
        method: 'GET'
    },
    SEND_OTP: {
        url: '/api/v1/otp',
        method: 'POST'
    },
    REGISTER_USER: {
        url: '/api/v1/user',
        method: 'POST'
    },
    My_INFO: {
        url: '/api/v1/me',
        method: 'GET'
    },
    BUSINESS_ID_AVAILABILITY: {
        url: '/api/v1/app/domainManagement/check-business-id-availability',
        method: 'POST'
    },
    ADD_BUSINESS_ID: {
        url: '/api/v1/app/domainManagement/add-business-id',
        method: 'POST'
    },
    ADD_USER_IN_DOMAIN: {
        url: '/api/v1/app/users/add-user',
        method: 'POST'
    },
    GET_FILTERED_USERS: {
        url: '/api/v1/app/users/get-filtered-user',
        method: 'GET'
    },
    CREATE_AUTHORIZATION_GROUP: {
        url: '/api/v1/app/authorization/create',
        method: 'POST'
    },
    SEARCH_AUTHORIZATION_GROUP: {
        url: '/api/v1/app/authorization/search',
        method: 'GET'
    },
    GET_AUTHORIZATION_GROUP_DETAILS: {
        url: '/api/v1/app/authorization/groupId',
        method: 'GET'
    },
    GET_AUTHORIZATION_GROUP_USERS: {
        url: '/api/v1/app/authorization/users/groupId',
        method: 'GET'
    },
    ADD_AUTHORIZATION_GROUP_USER: {
        url: '/api/v1/app/authorization/users/add/groupId',
        method: 'POST'
    },
    REMOVE_AUTHORIZATION_GROUP_USER: {
        url: '/api/v1/app/authorization/users/remove/groupId',
        method: 'POST'
    },
    GET_USER_DETAILS: {
        url: '/api/v1/app/users/get-user-details',
        method: 'GET'
    },
    GET_AVAILABLE_LICESNSE: {
        url: '/api/v1/app/appInstall/apps',
        method: 'GET'
    },
    UPDATE_AUTHORIZATION_GROUP: {
        url: '/api/v1/app/authorization/groupId',
        method: 'POST'
    },
    FORGOT_PASSWORD: {
        url: '/api/v1/app/users/send-forget-password-email',
        method: 'POST'
    },
    RESET_PASSWORD: {
        url: '/api/v1/app/users/update-user-password',
        method: 'POST'
    },
    SEARCH_TENANT: {
        url: '/api/v1/app/tenantManagement/searchTenant',
        method: 'POST'
    },
    ENABLE_TENANT: {
        url: '/api/v1/app/tenantManagement/enableTenant',
        method: 'POST'
    },
    DISABLE_TENANT: {
        url: '/api/v1/app/tenantManagement/disableTenant',
        method: 'POST'
    },
    GET_TENANT_DETAILS: {
        url: '/api/v1/app/tenantManagement/getTenantDetails',
        method: 'GET'
    },
    FORGOT_USERNAME: {
        url: '/api/v1/app/users/forget-username',
        method: 'POST'
    },
    REGISTER_ADMIN_USER_FROM_PUBLIC_EMAIL_DOMAIN: {
        url: '/api/v1/app/tenantManagement/registerAdminUserFromPublicEmailDomain',
        method: 'POST'
    },
    SET_PUBLIC_URL_SIGNUP: {
        url: '/api/v1/app/authorization/groupId/:groupId/public-url-signup',
        method: 'POST'
    },
    SET_PUBLIC_URL_SIGNUP_APPROVAL_REQUIRED: {
        url: '/api/v1/app/authorization/groupId/:groupId/public-url-signup-approval-required',
        method: 'POST'
    },
    GET_AUTHORIZATION_GROUP_PUBLIC_DETAILS: {
        url: '/api/v1/app/authorization/public/groupId/:groupId',
        method: 'GET'
    },
    JOIN_AUTHORIZATION_GROUP_USING_PUBLIC_LINK: {
        url: '/api/v1/app/authorization/public/join/groupId/:groupId',
        method: 'POST'
    },
    GET_AUTHORIZATION_GROUP_JOIN_REQUESTS: {
        url: '/api/v1/app/authorization/request/join/groupId/:groupId',
        method: 'GET'
    },
    APPROVE_OR_REJECT_AUTHORIZATION_GROUP_JOIN_REQUEST: {
        url: '/api/v1/app/authorization/request/join/groupId/:groupId/action',
        method: 'POST'
    },
    APPS_AVAILABEL_TO_INSTALL: {
        url: '/api/v1/app/appInstall/availableToInstall',
        method: 'GET'
    },
    INSTALLED_APPS: {
        url: '/api/v1/app/appInstall/installed',
        method: 'GET'
    },
    INSTALL_APP: {
        url: '/api/v1/app/appInstall/install',
        method: 'POST'
    },
    UNINSTALL_APP: {
        url: '/api/v1/app/appInstall/uninstall',
        method: 'POST'
    },
    CREATE_DEPARTMENT: {
        url: '/api/v1/app/department/department',
        method: 'POST'
    },
    GET_DEPARTMENTS: {
        url: '/api/v1/app/department/departments?includingInActive=',
        method: 'GET'
    },
    UPDATE_DEPARTMENT: {
        url: '/api/v1/app/department/department/:departmentId',
        method: 'PUT'
    },
    CREATE_DESIGNATION: {
        url: '/api/v1/app/designation/designation',
        method: 'POST'
    },
    GET_DESIGNATIONS: {
        url: '/api/v1/app/designation/designations?includingInActive=',
        method: 'GET'
    },
    UPDATE_DESIGNATION: {
        url: '/api/v1/app/designation/designation/:designationId',
        method: 'PUT'
    },
    GET_LEVEL_SUGGESTIONS: {
        url: '/api/v1/app/designation/level/:level/suggestions',
        method: 'GET'
    },
    UPDATE_USER_DETAILS: {
        url: '/api/v1/app/users/user/:userId',
        method: 'PATCH'
    },
    RENEW_AUTH_TOKEN: {
        url: '/api/v1/renew-token',
        method: 'POST'
    },
    CHANGE_USER_ACTIVE_STATE: {
        url: '/api/v1/app/users/user/:userId/active/:active',
        method: 'POST'
    },
    CREATE_LOCATION: {
        url: '/api/v1/app/locations/location',
        method: 'POST'
    },
    GET_LOCATIONS: {
        url: '/api/v1/app/locations/locations?includingInActive=',
        method: 'GET'
    },
    GET_LOCATION: {
        url: '/api/v1/app/locations/location/:locationId',
        method: 'GET'
    },
    UPDATE_LOCATION: {
        url: '/api/v1/app/locations/location/:locationId',
        method: 'PUT'
    },
    SET_USER_LOCATION: {
        url: '/api/v1/app/locations/user/:userId/location',
        method: 'POST'
    },
    GET_USER_LOCATION: {
        url: '/api/v1/app/locations/user/:userId/location',
        method: 'GET'
    },
    ADD_ACCOUNT: {
        url: '/api/v1/app/accounts/:type',
        method: 'POST'
    },
    SEARCH_ACCOUNTS: {
        url: '/api/v1/app/accounts/:type?q=:query&page=:page',
        method: 'GET'
    },
    UPDATE_ACCOUNT: {
        url: '/api/v1/app/accounts/:type/:accountId',
        method: 'PUT'
    },
    GET_ACCOUNT_CONTACTS: {
        url: '/api/v1/app/accounts/:type/:accountId/contacts',
        method: 'GET'
    },
    CREATE_CONTACT: {
        url: '/api/v1/app/accounts/:type/:accountId/contact',
        method: 'POST'
    },
    UPDATE_CONTACT: {
        url: '/api/v1/app/accounts/:type/:accountId/contact/:contactId',
        method: 'PUT'
    },
    CREATE_ITEM: {
        url: '/api/v1/app/inventory/item',
        method: 'POST'
    },
    GET_ITEMS: {
        url: '/api/v1/app/inventory/items?q=:query&pageNo=:pageNo',
        method: 'GET'
    },
    UPDATE_ITEM: {
        url: '/api/v1/app/inventory/item/:sku',
        method: 'PUT'
    },
    ENABLE_DISABLE_ITEM: {
        url: '/api/v1/app/inventory/item/:sku/state/:state',
        method: 'PUT'
    },
    GET_ITEMS_BY_LOCATION: {
        url: '/api/v1/app/inventory/items/location/:locationId?q=:query&pageNo=:pageNo',
        method: 'GET'
    },
    CORRECT_STOCK_LOCATION: {
        url: '/api/v1/app/inventory/item/:sku/location/:locationId',
        method: 'PUT'
    },
    MOVE_STOCK: {
        url: '/api/v1/app/inventory/item/:sku/move',
        method: 'POST'
    },
    RECEIVE_SUPPLY: {
        url: '/api/v1/app/inventory/receive',
        method: 'POST'
    },
    GET_PURCHASE_RECEIPTS: {
        url: '/api/v1/app/inventory/purchases?supplierId=:supplierId&locationId=:locationId&pageNo=:pageNo',
        method: 'GET'
    },
    GET_PURCHASE_RECEIPT: {
        url: '/api/v1/app/inventory/purchase-receipt/:purchaseReceiptId',
        method: 'GET'
    },
    SET_CONFIG: {
        url: '/api/v1/app/configManagement/config/:app/:key',
        method: 'POST'
    },
    GET_CONFIG: {
        url: '/api/v1/app/configManagement/config/:app/:key',
        method: 'GET'
    },
    CREATE_ORDER: {
        url: '/api/v1/app/sales/order',
        method: 'POST'
    },
    GET_ORDER: {
        url: '/api/v1/app/sales/order/:orderId',
        method: 'GET'
    },
    SEARCH_ORDERS: {
        url: '/api/v1/app/sales/orders?pageNo=:pageNo&locationId=:locationId&customerId=:customerId&userId=:userId',
        method: 'GET'
    },
    EMI_OVERDUE: {
        url: '/api/v1/app/sales/orders/emi/overdue?days=:days',
        method: 'GET'
    },
    RECEIVE_EMI_PAYMENT: {
        url: '/api/v1/app/sales/orders/emi/payment/:emiScheduleId',
        method: 'POST'
    },
    CREATE_PROJECT: {
        url: '/api/v1/app/textImageToVideo/project',
        method: 'POST'
    },
    GET_PROJECTS: {
        url: '/api/v1/app/textImageToVideo/projects?query=:query&pageNo=:pageNo',
        method: 'GET'
    },
    GET_PROJECT_DETAILS: {
        url: '/api/v1/app/textImageToVideo/project/:projectId',
        method: 'GET'
    },
    UPDATE_PROJECT_DETAILS: {
        url: '/api/v1/app/textImageToVideo/project/:projectId',
        method: 'PUT'
    },
    DELETE_PROJECT: {
        url: '/api/v1/app/textImageToVideo/project/:projectId',
        method: 'DELETE'
    },
    GET_PROJECT_CONTENTS: {
        url: '/api/v1/app/textImageToVideo/project/:projectId/contents',
        method: 'GET'
    },
    CREATE_TEXT_CONTENT: {
        url: '/api/v1/app/textImageToVideo/project/:projectId/content',
        method: 'POST'
    },
    UPDATE_TEXT_CONTENT: {
        url: '/api/v1/app/textImageToVideo/project/:projectId/content/:contentId',
        method: 'PUT'
    },
    DELETE_TEXT_CONTENT: {
        url: '/api/v1/app/textImageToVideo/project/:projectId/content/:contentId',
        method: 'DELETE'
    },
    UPLOAD_PROJECT_CONTENT_IMAGE: {
        url: '/api/v1/app/textImageToVideo/project/:projectId/content/:contentId/image',
        method: 'POST'
    },
    GENERATE_VIDEO: {
        url: '/api/v1/app/textImageToVideo/project/:projectId/generate-video',
        method: 'POST'
    },
    GET_TENANT_VIDEO_QUOTA: {
        url: '/api/v1/app/tenantManagement/videoQuota/:tenantId',
        method: 'GET'
    },
    ADD_TENANT_VIDEO_QUOTA: {
        url: '/api/v1/app/tenantManagement/videoQuota/:tenantId',
        method: 'PUT'
    }
};

export class ExplisoftServiceImpl implements ExplisoftService {
    constructor() {
        console.log('Intializing ExplisoftServiceImpl...');
    }
    
    public async addTenantVideoQuota(tenantId: string, quota: number): Promise<{message: string}> {
        const url = URL.ADD_TENANT_VIDEO_QUOTA.url.replace(':tenantId', tenantId);
        return await execute<{message: string}>({ ...URL.ADD_TENANT_VIDEO_QUOTA, url }, { quota });
    }
    
    public async getTenantVideoQuota(tenantId: string): Promise<{ quota: number; }> {
        const url = URL.GET_TENANT_VIDEO_QUOTA.url.replace(':tenantId', tenantId);
        return await execute<{ quota: number }>({ ...URL.GET_TENANT_VIDEO_QUOTA, url });
    }
    
    public async generateVideo(projectId: string, voice: "MALE" | "FEMALE"): Promise<{ message: string; }> {
        const url = URL.GENERATE_VIDEO.url.replace(':projectId', projectId);
        return await execute<{ message: string }>({ ...URL.GENERATE_VIDEO, url }, { voice });
    }
    
    public async uploadImage(projectId: string, contentId: string, file: File): Promise<{imageUrl: string}> {
        const url = URL.UPLOAD_PROJECT_CONTENT_IMAGE.url.replace(':projectId', projectId).replace(':contentId', contentId);
        const formData = new FormData();
        formData.append('image', file);
        return fetch(url, {
            method: 'POST',
            body: formData
        }).then(async (response) => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson ? await response.json() : null;
            if (!response.ok) {
                return Promise.reject(data || response.statusText);
            }
            return data;
        })
        .catch((error: any) => {
            if (error?.errorCode === 'NOT_AUTHENTICATED') {
                window.location.href = '/auth/login';
            }
            throw error;
        });
    }

    public async getProjectContents(projectId: string): Promise<ProjectContent[]> {
        const url = URL.GET_PROJECT_CONTENTS.url.replace(':projectId', projectId);
        return await execute<ProjectContent[]>({ ...URL.GET_PROJECT_CONTENTS, url });
    }

    public async createProjectContent(projectId: string, content: string): Promise<ProjectContent> {
        const url = URL.CREATE_TEXT_CONTENT.url.replace(':projectId', projectId);
        return await execute<ProjectContent>({ ...URL.CREATE_TEXT_CONTENT, url }, { content });
    }

    public async updateProjectContent(projectId: string, contentId: string, content: string): Promise<ProjectContent> {
        const url = URL.UPDATE_TEXT_CONTENT.url.replace(':projectId', projectId).replace(':contentId', contentId);
        return await execute<ProjectContent>({ ...URL.UPDATE_TEXT_CONTENT, url }, { content });
    }

    public async deleteProjectContent(projectId: string, contentId: string): Promise<void> {
        const url = URL.DELETE_TEXT_CONTENT.url.replace(':projectId', projectId).replace(':contentId', contentId);
        return await execute<void>({ ...URL.DELETE_TEXT_CONTENT, url });
    }

    public async createProject(project: ProjectRequest): Promise<Project> {
        return await execute<Project>(URL.CREATE_PROJECT, project);
    };

    public async getProjects(query: string, pageNo: number): Promise<{ hasMore: boolean; projects: Project[]; }> {
        const url = URL.GET_PROJECTS.url.replace(':query', query).replace(':pageNo', pageNo.toString());
        return await execute<{ hasMore: boolean; projects: Project[]; }>({ ...URL.GET_PROJECTS, url });
    }

    public async getProjectDetails(projectId: string): Promise<Project> {
        const url = URL.GET_PROJECT_DETAILS.url.replace(':projectId', projectId);
        return await execute<Project>({ ...URL.GET_PROJECT_DETAILS, url });
    }

    public async updateProjectDetails(projectId: string, project: ProjectRequest): Promise<Project> {
        const url = URL.UPDATE_PROJECT_DETAILS.url.replace(':projectId', projectId);
        return await execute<Project>({ ...URL.UPDATE_PROJECT_DETAILS, url }, project);
    }

    public async deleteProject(projectId: string): Promise<void> {
        const url = URL.DELETE_PROJECT.url.replace(':projectId', projectId);
        return await execute<void>({ ...URL.DELETE_PROJECT, url });
    }


    public async receiveEMIPayment(
        emiScheduleId: string,
        amount: number,
        paymentDate: Date,
        paymentMode: string,
        paymentReference: string
    ): Promise<{ message: string }> {
        const url = URL.RECEIVE_EMI_PAYMENT.url.replace(':emiScheduleId', emiScheduleId);
        return await execute<{ message: string }>(
            {
                ...URL.RECEIVE_EMI_PAYMENT,
                url
            },
            { amount, paymentDate, paymentMode, paymentReference }
        );
    }

    public async emiOverdue(days: number): Promise<{ data: OrderBasic[] }> {
        const url = URL.EMI_OVERDUE.url.replace(':days', days.toString());
        return await execute<{ data: OrderBasic[] }>({ ...URL.EMI_OVERDUE, url });
    }

    public async searchOrders(filter: OrderFilter, pageNo = 1): Promise<{ data: OrderBasic[]; hasMore: boolean }> {
        const url = URL.SEARCH_ORDERS.url
            .replace(':pageNo', pageNo.toString())
            .replace(':locationId', filter.locationId ?? '')
            .replace(':customerId', filter.customerId ?? '')
            .replace(':userId', filter.userId ?? '');
        return await execute<{ data: OrderBasic[]; hasMore: boolean }>({ ...URL.SEARCH_ORDERS, url });
    }

    public async getOrder(orderId: string): Promise<Order> {
        const url = URL.GET_ORDER.url.replace(':orderId', orderId);
        return await execute<Order>({ ...URL.GET_ORDER, url });
    }

    public async createOrder(order: OrderRequest): Promise<Order> {
        return await execute<Order>(URL.CREATE_ORDER, order);
    }

    public async setConfig(app: string, key: string, value: string): Promise<Config> {
        const url = URL.SET_CONFIG.url.replace(':app', app).replace(':key', key);
        return await execute<Config>({ ...URL.SET_CONFIG, url }, { value });
    }

    public async getConfig(app: string, key: string): Promise<Config> {
        const url = URL.GET_CONFIG.url.replace(':app', app).replace(':key', key);
        return await execute<Config>({ ...URL.GET_CONFIG, url });
    }

    public async getPurchaseReceipt(purchaseReceiptId: string): Promise<ReceiveSupply> {
        const url = URL.GET_PURCHASE_RECEIPT.url.replace(':purchaseReceiptId', purchaseReceiptId);
        return await execute<ReceiveSupply>({ ...URL.GET_PURCHASE_RECEIPT, url });
    }

    public async getAllPurchaseReceipts(
        param: { locationId?: string; supplierId?: string },
        page: number
    ): Promise<{ hasMore: boolean; items: PurchaseReceiptBasic[] }> {
        const url = URL.GET_PURCHASE_RECEIPTS.url
            .replace(':locationId', param.locationId ?? '')
            .replace(':supplierId', param.supplierId ?? '')
            .replace(':pageNo', page.toString());
        return await execute<{ hasMore: boolean; items: PurchaseReceiptBasic[] }>({ ...URL.GET_PURCHASE_RECEIPTS, url });
    }

    public async receiveSupply(receiveSupply: ReceiveSupplyRequest): Promise<ReceiveSupply> {
        return await execute<ReceiveSupply>(URL.RECEIVE_SUPPLY, receiveSupply);
    }

    public async moveStock(moveStock: MoveStock): Promise<Stock> {
        const url = URL.MOVE_STOCK.url.replace(':sku', moveStock.sku.toString());
        return await execute<Stock>(
            { ...URL.MOVE_STOCK, url },
            {
                quantity: moveStock.quantity,
                fromLocationId: moveStock.fromLocationId,
                toLocationId: moveStock.toLocationId,
                reason: moveStock.reason
            }
        );
    }

    public async correctStockByLocation(correction: QuantityCorrection): Promise<ItemStock> {
        const url = URL.CORRECT_STOCK_LOCATION.url.replace(':sku', correction.sku.toString()).replace(':locationId', correction.locationId);
        return await execute<ItemStock>(
            { ...URL.CORRECT_STOCK_LOCATION, url },
            { quantityCorrection: correction.quantity, reason: correction.reason }
        );
    }

    public async getAllItemsStockByLocation(
        locationId: string,
        query: string,
        page: number
    ): Promise<{ hasMore: boolean; items: ItemStock[] }> {
        const url = URL.GET_ITEMS_BY_LOCATION.url
            .replace(':locationId', locationId)
            .replace(':query', query)
            .replace(':pageNo', page.toString());
        return await execute<{ hasMore: boolean; items: ItemStock[] }>({ ...URL.GET_ITEMS_BY_LOCATION, url });
    }

    public async enableDisableItem(sku: number, enable: boolean): Promise<Item> {
        const url = URL.ENABLE_DISABLE_ITEM.url.replace(':sku', sku.toString()).replace(':state', enable.toString());
        return await execute<Item>({ ...URL.ENABLE_DISABLE_ITEM, url });
    }

    public async updateItem(sku: number, item: ItemRequest): Promise<Item> {
        const url = URL.UPDATE_ITEM.url.replace(':sku', sku.toString());
        return await execute<Item>({ ...URL.UPDATE_ITEM, url }, item);
    }

    public async getItems(query: string, page = 1): Promise<{ hasMore: boolean; items: Item[] }> {
        const url = URL.GET_ITEMS.url.replace(':query', query).replace(':pageNo', page.toString());
        return await execute<{ hasMore: boolean; items: Item[] }>({ ...URL.GET_ITEMS, url });
    }

    public async createItem(item: ItemRequest): Promise<Item> {
        return await execute<Item>(URL.CREATE_ITEM, item);
    }

    public async getContacts(accountType: AccountType, accountId: string): Promise<Contact[]> {
        const url = URL.GET_ACCOUNT_CONTACTS.url.replace(':type', accountType).replace(':accountId', accountId);
        return await execute<Contact[]>({ ...URL.GET_ACCOUNT_CONTACTS, url });
    }

    public async createContact(accountType: AccountType, accountId: string, contact: ContactRequest): Promise<Contact> {
        const url = URL.CREATE_CONTACT.url.replace(':type', accountType).replace(':accountId', accountId);
        return await execute<Contact>({ ...URL.CREATE_CONTACT, url }, contact);
    }

    public async updateContact(accountType: AccountType, accountId: string, contactId: string, contact: ContactRequest): Promise<Contact> {
        const url = URL.UPDATE_CONTACT.url.replace(':type', accountType).replace(':accountId', accountId).replace(':contactId', contactId);
        return await execute<Contact>({ ...URL.UPDATE_CONTACT, url }, contact);
    }

    public async updateAccount(accountType: AccountType, accountId: string, account: AccountRequest): Promise<Account> {
        const url = URL.UPDATE_ACCOUNT.url.replace(':type', accountType).replace(':accountId', accountId);
        return await execute<Account>({ ...URL.UPDATE_ACCOUNT, url }, account);
    }

    public async getAccounts(accountType: AccountType, query: string, page = 1): Promise<Account[]> {
        const url = URL.SEARCH_ACCOUNTS.url.replace(':type', accountType).replace(':query', query).replace(':page', page.toString());
        return await execute<Account[]>({ ...URL.SEARCH_ACCOUNTS, url });
    }

    public async createAccount(accountType: AccountType, account: AccountRequest): Promise<Account> {
        const url = URL.ADD_ACCOUNT.url.replace(':type', accountType);
        return await execute<Account>({ ...URL.ADD_ACCOUNT, url }, account);
    }

    public async createLocation(location: LocationRequest): Promise<Location> {
        return await execute<Location>(URL.CREATE_LOCATION, location);
    }

    public async getLocation(locationId: string): Promise<Location> {
        const url = URL.GET_LOCATION.url.replace(':locationId', locationId);
        return await execute<Location>({ ...URL.GET_LOCATION, url });
    }

    public async updateLocation(locationId: string, location: LocationUpdateRequest): Promise<Location> {
        const url = URL.UPDATE_LOCATION.url.replace(':locationId', locationId);
        return await execute<Location>({ ...URL.UPDATE_LOCATION, url }, location);
    }

    public async getLocations(includingInActive: boolean): Promise<Location[]> {
        const policy = cache.get<Policy>(Key.POLICY);
        const canFetchAllLocations = policy?.licenseAndScope['locations']?.includes('view_all_locations');
        if (!canFetchAllLocations) {
            const myInfo = cache.get<MyInfo>(Key.USER_INFO);
            if (myInfo.location) {
                return Promise.resolve([myInfo.location]);
            } else {
                return Promise.resolve([]);
            }
        }
        const url = URL.GET_LOCATIONS.url + includingInActive;
        return await execute<Location[]>({ ...URL.GET_LOCATIONS, url });
    }

    public async changeLocationActiveState(locationId: string, active: boolean): Promise<Location> {
        const url = URL.UPDATE_LOCATION.url.replace(':locationId', locationId);
        return await execute<Location>(
            {
                ...URL.UPDATE_LOCATION,
                url
            },
            { isActive: active }
        );
    }

    public async addUserToLocation(locationId: string, userId: string): Promise<{ message: string }> {
        const url = URL.SET_USER_LOCATION.url.replace(':userId', userId);
        return await execute<{ message: string }>(
            {
                ...URL.SET_USER_LOCATION,
                url
            },
            { locationId }
        );
    }

    public async getUserLocation(userId: string): Promise<Location> {
        const url = URL.GET_USER_LOCATION.url.replace(':userId', userId);
        return await execute<Location>({ ...URL.GET_USER_LOCATION, url });
    }

    public async changeUserActiveState(userId: string, active: boolean): Promise<{ message: string }> {
        const url = URL.CHANGE_USER_ACTIVE_STATE.url.replace(':userId', userId).replace(':active', active.toString());
        return await execute<{ message: string }>({ ...URL.CHANGE_USER_ACTIVE_STATE, url });
    }

    public async renewToken(): Promise<AuthToken> {
        const authToken = await execute<{ token: string; expiry: string }>(URL.RENEW_AUTH_TOKEN);
        return {
            token: authToken.token,
            expiry: new Date(authToken.expiry)
        };
    }

    public async updateUserDetails(userId: string, patchParam: UserPatchParam): Promise<{ message: string }> {
        const url = URL.UPDATE_USER_DETAILS.url.replace(':userId', userId);
        return await execute<{ message: string }>(
            {
                ...URL.UPDATE_USER_DETAILS,
                url
            },
            patchParam
        );
    }

    public async createDesignation(name: string, description: string, level: number): Promise<Designation> {
        return await execute<Designation>(URL.CREATE_DESIGNATION, { name, description, level });
    }

    public async getDesignations(includingInActive: boolean = false): Promise<Designation[]> {
        return await execute<Designation[]>({
            ...URL.GET_DESIGNATIONS,
            url: URL.GET_DESIGNATIONS.url + includingInActive
        });
    }

    public async changeDesignationActiveState(designation: Designation, active: boolean): Promise<Designation> {
        const url = URL.UPDATE_DESIGNATION.url.replace(':designationId', designation.designationId);
        return await execute<Designation>(
            {
                ...URL.UPDATE_DESIGNATION,
                url
            },
            { name: designation.name, description: designation.description, active, level: designation.level }
        );
    }

    public async updateDesignation(designation: Designation): Promise<Designation> {
        const url = URL.UPDATE_DESIGNATION.url.replace(':designationId', designation.designationId);
        return await execute<Designation>(
            {
                ...URL.UPDATE_DESIGNATION,
                url
            },
            { name: designation.name, description: designation.description, active: designation.active, level: designation.level }
        );
    }

    public async getLevelSuggestions(level: number): Promise<LevelSuggestions> {
        const url = URL.GET_LEVEL_SUGGESTIONS.url.replace(':level', level.toString());
        return await execute<LevelSuggestions>({ ...URL.GET_LEVEL_SUGGESTIONS, url });
    }

    public async updateDepartment(department: Department): Promise<Department> {
        const url = URL.UPDATE_DEPARTMENT.url.replace(':departmentId', department.departmentId);
        return await execute<Department>(
            {
                ...URL.UPDATE_DEPARTMENT,
                url
            },
            { name: department.name, description: department.description, active: department.active }
        );
    }

    public async changeDepartmentActiveState(department: Department, active: boolean): Promise<Department> {
        const url = URL.UPDATE_DEPARTMENT.url.replace(':departmentId', department.departmentId);
        return await execute<Department>(
            {
                ...URL.UPDATE_DEPARTMENT,
                url
            },
            { name: department.name, description: department.description, active }
        );
    }

    public async getDepartments(includingInActive: boolean = false): Promise<Department[]> {
        return await execute<Department[]>({
            ...URL.GET_DEPARTMENTS,
            url: URL.GET_DEPARTMENTS.url + includingInActive
        });
    }

    public async createDepartment(name: string, description: string): Promise<Department> {
        return await execute<Department>(URL.CREATE_DEPARTMENT, { name, description });
    }

    public async installApp(appId: string): Promise<{ message: string }> {
        return await execute<{ message: string }>(URL.INSTALL_APP, { licenseId: appId });
    }

    public async uninstallApp(appId: string): Promise<{ message: string }> {
        return await execute<{ message: string }>(URL.UNINSTALL_APP, { licenseId: appId });
    }

    public async installedApps(): Promise<LicenseBasic[]> {
        return await execute<LicenseBasic[]>(URL.INSTALLED_APPS);
    }

    public async availableToInstall(): Promise<LicenseBasic[]> {
        return await execute<LicenseBasic[]>(URL.APPS_AVAILABEL_TO_INSTALL);
    }

    public async getAuthorizationGroupJoinRequests(groupId: string): Promise<JoinAuthGroupRequest[]> {
        const url = URL.GET_AUTHORIZATION_GROUP_JOIN_REQUESTS.url.replace(':groupId', groupId);
        return await execute<JoinAuthGroupRequest[]>({ ...URL.GET_AUTHORIZATION_GROUP_JOIN_REQUESTS, url });
    }

    public async approveOrRejectAuthorizationGroupJoinRequest(
        request: JoinAuthGroupRequest,
        approved: boolean
    ): Promise<{ message: string }> {
        const url = URL.APPROVE_OR_REJECT_AUTHORIZATION_GROUP_JOIN_REQUEST.url.replace(':groupId', request.groupId);
        return await execute<{ message: string }>(
            {
                ...URL.APPROVE_OR_REJECT_AUTHORIZATION_GROUP_JOIN_REQUEST,
                url
            },
            { ...request, approved }
        );
    }

    public async joinAuthorizationGroupUsingPublicLink(
        groupId: string,
        emailId: string,
        firstName: string,
        lastName: string
    ): Promise<{ message: string }> {
        const url = URL.JOIN_AUTHORIZATION_GROUP_USING_PUBLIC_LINK.url.replace(':groupId', groupId);
        return await execute<{ message: string }>(
            {
                ...URL.JOIN_AUTHORIZATION_GROUP_USING_PUBLIC_LINK,
                url
            },
            { emailId, firstName, lastName }
        );
    }

    public async getAuthorizationGroupPublicDetails(groupId: string): Promise<AuthorizationGroupPublicDetails> {
        return await execute<AuthorizationGroupPublicDetails>({
            ...URL.GET_AUTHORIZATION_GROUP_PUBLIC_DETAILS,
            url: URL.GET_AUTHORIZATION_GROUP_PUBLIC_DETAILS.url.replace(':groupId', groupId)
        });
    }

    public async setPublicUrlSignup(groupId: string, enable: boolean): Promise<{ message: string }> {
        const url = URL.SET_PUBLIC_URL_SIGNUP.url.replace(':groupId', groupId);
        return await execute<{ message: string }>(
            {
                ...URL.SET_PUBLIC_URL_SIGNUP,
                url
            },
            { groupId, enable }
        );
    }

    public async setPublicUrlSignupApprovalRequired(groupId: string, enable: boolean): Promise<{ message: string }> {
        const url = URL.SET_PUBLIC_URL_SIGNUP_APPROVAL_REQUIRED.url.replace(':groupId', groupId);
        return await execute<{ message: string }>(
            {
                ...URL.SET_PUBLIC_URL_SIGNUP_APPROVAL_REQUIRED,
                url
            },
            { groupId, enable }
        );
    }

    public async registerAdminUserFromPublicEmailDomain(request: RegisterAdminUserFromPublicEmailDomain): Promise<{ message: string }> {
        return await execute<{ message: string }>(URL.REGISTER_ADMIN_USER_FROM_PUBLIC_EMAIL_DOMAIN, request);
    }

    public async searchTenant(query: string): Promise<Tenant[]> {
        return await execute<Tenant[]>(URL.SEARCH_TENANT, { query });
    }

    public async login(userName: string, password: string): Promise<AuthToken> {
        const authToken = await execute<{ token: string; expiry: string }>(URL.LOGIN, { userName, password });
        return {
            token: authToken.token,
            expiry: new Date(authToken.expiry)
        };
    }

    public async validateEmail(emailId: string): Promise<EmailValidationResult> {
        const api = {
            ...URL.VALIDAT_EMAIL,
            url: URL.VALIDAT_EMAIL.url.replace(':emailId', emailId)
        };
        return await execute<EmailValidationResult>(api);
    }

    public async generateOTP(email: string): Promise<{ otpId: string }> {
        const otp = await execute<{ id: string }>(URL.SEND_OTP, { email });
        return {
            otpId: otp.id
        };
    }

    public async registerUser(registerUserRequest: RegisterUserRequest): Promise<ApiGenericResponse> {
        return await execute<ApiGenericResponse>(URL.REGISTER_USER, registerUserRequest);
    }

    public async me(): Promise<MyInfo> {
        return await execute<MyInfo>(URL.My_INFO);
    }

    public async checkBusinessIdAvailability(businessId: string): Promise<{ available: boolean }> {
        return await execute<{ available: boolean }>(URL.BUSINESS_ID_AVAILABILITY, { businessId });
    }

    public async addDomainBusinessId(businessId: string): Promise<boolean> {
        await execute<void>(URL.ADD_BUSINESS_ID, { businessId });
        return Promise.resolve(true);
    }

    public async addUserInDomain(
        userName: string,
        firstName: string,
        lastName: string,
        emailId: string,
        manager?: string
    ): Promise<{ message: string }> {
        return await execute<{ message: string }>(URL.ADD_USER_IN_DOMAIN, { userName, firstName, lastName, emailId, manager });
    }

    public async getFilteredUser(query: string, showInactive = false): Promise<User[]> {
        return await execute<User[]>({
            ...URL.GET_FILTERED_USERS,
            url: URL.GET_FILTERED_USERS.url + '?q=' + query + '&showInactive=' + showInactive
        });
    }

    public async createAuthorizationGroup(name: string): Promise<AuthorizationGroupDetails> {
        return await execute<AuthorizationGroupDetails>(URL.CREATE_AUTHORIZATION_GROUP, { name });
    }

    public async searchAuthGroup(groupName: string, owner: string, scope: string): Promise<AuthorizationGroup[]> {
        const api = {
            ...URL.SEARCH_AUTHORIZATION_GROUP,
            url: `${URL.SEARCH_AUTHORIZATION_GROUP.url}?groupName=${groupName}&owner=${owner}&scope=${scope}`
        };
        return await execute<AuthorizationGroup[]>(api);
    }

    public async getAuthorizationGroupDetails(groupId: string): Promise<AuthorizationGroupDetails> {
        const api = {
            ...URL.GET_AUTHORIZATION_GROUP_DETAILS,
            url: `${URL.GET_AUTHORIZATION_GROUP_DETAILS.url}/${groupId}`
        };
        return await execute<AuthorizationGroupDetails>(api);
    }

    public async getAuthorizationGroupUsers(groupId: string): Promise<User[]> {
        const api = {
            ...URL.GET_AUTHORIZATION_GROUP_USERS,
            url: `${URL.GET_AUTHORIZATION_GROUP_USERS.url}/${groupId}`
        };
        return await execute<User[]>(api);
    }

    public async addUserInAuthorizationGroup(groupId: string, userId: string): Promise<void> {
        const api = {
            ...URL.ADD_AUTHORIZATION_GROUP_USER,
            url: `${URL.ADD_AUTHORIZATION_GROUP_USER.url}/${groupId}`
        };
        await execute<void>(api, { userId });
    }

    public async removeUserFromAuthorizationGroup(groupId: string, userId: string): Promise<void> {
        const api = {
            ...URL.REMOVE_AUTHORIZATION_GROUP_USER,
            url: `${URL.REMOVE_AUTHORIZATION_GROUP_USER.url}/${groupId}`
        };
        await execute<void>(api, { userId });
    }

    public async getUserDetails(userId: string, withManagerInfo: boolean = false, withMetaInfo: boolean = false): Promise<UserDetails> {
        const api = {
            ...URL.GET_USER_DETAILS,
            url: `${URL.GET_USER_DETAILS.url}/${userId}?withManagerInfo=${withManagerInfo}&withMetaInfo=${withMetaInfo}`
        };
        return await execute<UserDetails>(api);
    }

    public async getAvailableLicense(): Promise<License[]> {
        return await execute<License[]>(URL.GET_AVAILABLE_LICESNSE);
    }

    public async saveAuthGroup(groupId: string, groupName: string, owners: string[], scopes: string[]): Promise<AuthorizationGroupDetails> {
        const api = {
            ...URL.UPDATE_AUTHORIZATION_GROUP,
            url: `${URL.UPDATE_AUTHORIZATION_GROUP.url}/${groupId}`
        };
        return await execute<AuthorizationGroupDetails>(api, { groupName, owners, licenseScopes: scopes });
    }

    public async forgotPassword(username: string): Promise<{ message: string }> {
        return await execute<{ message: string }>(URL.FORGOT_PASSWORD, { userName: username });
    }

    public async resetPassword(password: string, token: string): Promise<{ message: string }> {
        return await execute<{ message: string }>(URL.RESET_PASSWORD, { password, token });
    }

    public async enableTenant(tenantId: string): Promise<void> {
        return await execute<void>(URL.ENABLE_TENANT, { tenantId });
    }
    public async disableTenant(tenantId: string): Promise<void> {
        return await execute<void>(URL.DISABLE_TENANT, { tenantId });
    }

    public async getTenantDetails(tenantId: string): Promise<Tenant> {
        const api = {
            ...URL.GET_TENANT_DETAILS,
            url: `${URL.GET_TENANT_DETAILS.url}/${tenantId}`
        };
        return await execute<Tenant>(api);
    }

    public async forgotUsername(emailId: string): Promise<{ message: string }> {
        return await execute<{ message: string }>(URL.FORGOT_USERNAME, { emailId });
    }
}
