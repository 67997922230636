import React from 'react';
import Toggle from 'react-toggle';
require('./style.css');

export interface IToggleButtonProps {
    value: boolean;
    onChange: (value: boolean, e?: any) => void;
    activateLabel?: string;
    deactivateLabel?: string;
    width?: string;
}

export const ToggleButton = (props: IToggleButtonProps) => {
    const handleClick = React.useCallback(
        (e) => {
            props.onChange(!props.value, e);
        },
        [props]
    );

    const label = props.value ? props.deactivateLabel : props.activateLabel;

    return (
        <div style={{ display: 'flex', width: props.width, padding: '3px' }}>
            {label && (
                <label style={{ marginRight: '5px' }} htmlFor="isActive">
                    {label}
                </label>
            )}
            <Toggle id="isActive" checked={props.value} onChange={handleClick} />
        </div>
    );
};
