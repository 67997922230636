import * as React from 'react';
import { explisoftService, Department } from '@services';
import { IBasePickerProps, BasePicker, Data } from '../base-picker';

const getLabel = (option: Department) => `${option.name} (${option.description})`;

export type IDepartmentPickerProps = Pick<
    IBasePickerProps<Department & Data>,
    Exclude<keyof IBasePickerProps<Department & Data>, 'getLabel' | 'getData' | 'getObjectId'>
>;

export const DepartmentPicker = ({
    multiple = false,
    id = 'DepartmentPicker',
    key,
    style = {},
    onSelect,
    label,
    initalValue = []
}: IDepartmentPickerProps) => {
    const [departments, setDepartments] = React.useState<Department[]>([]);
    React.useEffect(() => {
        explisoftService.getDepartments().then((data) => {
            setDepartments(data);
        });
    }, []);

    const getData = (query?: string) =>
        Promise.resolve(
            departments
                .filter(
                    (option) =>
                        option.name.toLowerCase().includes(query.toLowerCase()) ||
                        option.description.toLowerCase().includes(query.toLowerCase())
                )
                .map((option) => ({ ...option, id: option.departmentId }))
        );

    return (
        <BasePicker<Department & Data>
            multiple={multiple}
            id={id}
            key={key}
            style={style}
            onSelect={onSelect}
            label={label}
            initalValue={initalValue}
            getLabel={getLabel}
            getData={getData}
        />
    );
};
