import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import { cache, Key } from '@services';
import { Policy } from '../../../../../views/apps/policy.interface';
// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

import domainManagement from '../../../../../views/apps/domainManagement/app-policy';
import userManagement from '../../../../../views/apps/userManagement/app-policy';
import authorization from '../../../../../views/apps/authorization/app-policy';
import tenantManagement from '../../../../../views/apps/tenantManagement/app-policy';
import appInstall from '../../../../../views/apps/appInstall/app-policy';
import department from '../../../../../views/apps/department/app-policy';
import designation from '../../../../../views/apps/designation/app-policy';
import locations from '../../../../../views/apps/locations/app-policy';
import accounts from '../../../../../views/apps/accounts/app-policy';
import inventory from '../../../../../views/apps/inventory/app-policy';
import sales from '../../../../../views/apps/sales/app-policy';
import textImageToVideo from '../../../../../views/apps/textImageToVideo/app-policy';
/*** DO NOT DELETE THIS LINE FROM HERE, AUTOMATED, IMPORT POLICY VALIDATOR HERE ***/

import { useSelector } from 'react-redux';

const policyValidators = {
    'app-domainManagement': domainManagement,
    'app-userManagement': userManagement,
    'app-authorization': authorization,
    'app-tenantManagement': tenantManagement,
    'app-appInstall': appInstall,
    'app-designation': designation,
    'app-locations': locations,
    'app-accounts': accounts,
    'app-inventory': inventory,
    'app-sales': sales,
    'app-textImageToVideo': textImageToVideo,
    /*** DO NOT DELETE THIS LINE FROM HERE, AUTOMATED, INSERT POLICY VALIDATOR ENTRY HERE ***/
    'app-department': department
};

const NavGroup = ({ item }) => {
    const theme = useTheme();
    const policy = cache.get<Policy>(Key.POLICY);

    /* @ts-ignore */
    const leftDrawerOpened = useSelector((state) => state.customization.opened);

    // menu list collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} />;
            case 'item':
                if (policyValidators[menu.id]?.validate(policy) === false) {
                    return null;
                }
                return <NavItem key={menu.id} item={menu} level={1} />;
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <>
            <List
                subheader={
                    item.title &&
                    leftDrawerOpened && (
                        /* @ts-ignore */
                        <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                            {item.title}
                            {item.caption && (
                                /* @ts-ignore */
                                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {items}
            </List>

            {/* group divider */}
            <Divider sx={{ mt: 0.25, mb: 1.25 }} />
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
