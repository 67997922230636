import { createStore } from 'redux';
import reducer from './reducer';
import { loadState, saveState } from './localstorage';

// ==============================|| REDUX - MAIN STORE ||============================== //

const persistedState = loadState();
const store = createStore(reducer, persistedState);

store.subscribe(() => {
    saveState(store.getState());
});

export { store };
