import { Designation, explisoftService, error as errorToast, LevelSuggestions } from '@services';
import { debounce } from 'lodash';
import React from 'react';

const DEBOUNCE = 500;
export const useDesignationManagement = (includingInActive: boolean, level: null | number) => {
    const [designations, setDesignations] = React.useState<Designation[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');
    const [levelSuggestion, setLevelSuggestion] = React.useState<LevelSuggestions | null>(null);

    const refreshDesignations = React.useCallback(async () => {
        setLoading(true);
        setError('');
        setDesignations([]);
        return explisoftService
            .getDesignations(includingInActive)
            .then((response) => {
                setDesignations(response);
            })
            .catch((err) => {
                errorToast(err.message || 'Something went wrong while fetching designations');
                setError(err.message);
                throw err;
            })
            .finally(() => {
                setLoading(false);
            });
    }, [includingInActive]);

    const debounceRefreshDesignations = React.useMemo(() => debounce(refreshDesignations, DEBOUNCE), [refreshDesignations]);

    React.useEffect(() => {
        debounceRefreshDesignations();
    }, [includingInActive, debounceRefreshDesignations]);

    const addDesignation = React.useCallback(
        async (name: string, description: string, level: number) => {
            setLoading(true);
            setError('');
            return explisoftService
                .createDesignation(name, description, level)
                .then(() => {
                    refreshDesignations();
                })
                .catch((err) => {
                    errorToast(err.message || 'Something went wrong while adding designation');
                    setError(err.message);
                    throw err;
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [refreshDesignations]
    );

    const updateDesignation = React.useCallback(
        async (designation: Designation) => {
            setLoading(true);
            setError('');
            return explisoftService
                .updateDesignation(designation)
                .then(() => {
                    refreshDesignations();
                })
                .catch((err) => {
                    errorToast(err.message || 'Something went wrong while updating designation');
                    setError(err.message);
                    throw err;
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [refreshDesignations]
    );

    const changeDesignationActiveState = React.useCallback(
        async (designation: Designation, active: boolean) => {
            setLoading(true);
            setError('');
            return explisoftService
                .changeDesignationActiveState(designation, active)
                .then(() => {
                    refreshDesignations();
                })
                .catch((err) => {
                    errorToast(err.message || 'Something went wrong while changing designation active state');
                    setError(err.message);
                    throw err;
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [refreshDesignations]
    );

    const getLevelSuggestions = React.useCallback(async (level: number) => {
        setLoading(true);
        setError('');
        setLevelSuggestion(null);
        return explisoftService
            .getLevelSuggestions(level)
            .then((response) => {
                if (level !== response.level) return;
                setLevelSuggestion(response);
                return response;
            })
            .catch((err) => {
                errorToast(err.message || 'Something went wrong while fetching level suggestions');
                setError(err.message);
                throw err;
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const debounceGetLevelSuggestions = React.useMemo(() => debounce(getLevelSuggestions, DEBOUNCE), [getLevelSuggestions]);

    React.useEffect(() => {
        setLevelSuggestion(null);
        if (level) debounceGetLevelSuggestions(level);
    }, [level, debounceGetLevelSuggestions]);

    return {
        designations,
        loading,
        error,
        refreshDesignations,
        addDesignation,
        updateDesignation,
        changeDesignationActiveState,
        levelSuggestion
    };
};
