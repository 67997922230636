import { cache, Key } from '@services';

export interface ToastMessage {
    message: string;
    type: 'success' | 'error' | 'info' | 'warn';
}

export const info = (message: string) => {
    cache.put<ToastMessage>(Key.TOAST, { message, type: 'info' });
};

export const success = (message: string) => {
    cache.put<ToastMessage>(Key.TOAST, { message, type: 'success' });
};

export const error = (message: string) => {
    cache.put<ToastMessage>(Key.TOAST, { message, type: 'error' });
};

export const warn = (message: string) => {
    cache.put<ToastMessage>(Key.TOAST, { message, type: 'warn' });
};
