import React from 'react';
import { IconTrash, IconCheck, IconX } from '@tabler/icons';
import { IconButton, Popover, Typography } from '@mui/material';

export const DeleteWithConfirmation = (props: { onConfirm: () => void, onCancel?: () => void, message?: string }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation?.();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation?.();
        setAnchorEl(null);
    };

    const handleConfirm = React.useCallback((event) => {
        event.stopPropagation?.();
        props.onConfirm();
        handleClose(event);
    }, [props]);

    const handleCancel = React.useCallback((event) => {
        event.stopPropagation?.();
        props.onCancel?.();
        handleClose(event);
    }, [props]);

    const open = Boolean(anchorEl);
    return (
        <>
            <IconButton onClick={handleClick}>
                <IconTrash />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <Typography sx={{ p: 2 }}>{props.message || 'Are you sure?'}</Typography>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <IconButton onClick={handleConfirm}><IconCheck /></IconButton>
                    <IconButton onClick={handleCancel}><IconX /></IconButton>
                </div>
            </Popover>
        </>
    )
}