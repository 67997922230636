import React from "react";
import { usePermission, useUserDetails } from "@hooks";
import {
  Avatar,
  DepartmentPicker,
  DesignationPicker,
  LocationPicker,
  PeoplePicker,
  Wait,
} from "@components";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Link,
  Typography,
  TextField,
  Button,
  Tooltip,
} from "@mui/material";
import {
  Department,
  Designation,
  Key,
  MyInfo,
  User,
  UserDetails,
  error,
  explisoftService,
  UserPatchParam,
  success,
  Location,
} from "@services";
import {
  IconDeviceFloppy,
  IconEdit,
  IconMail,
  IconMessageCircle,
  IconUserCheck,
  IconUserX,
  IconUsers,
  IconX,
  IconUserExclamation,
} from "@tabler/icons";

const canEdit = (user: User, me: MyInfo | null, editable: boolean) => {
  if (!me) return false;
  if (!user) return false;
  if (user.isOwner && !me?.isOwner) {
    return false;
  }
  if (me?.isOwner) {
    return true;
  }
  return editable;
};

const canDisable = (user: User, me: MyInfo | null, editable: boolean) => {
  if (!me) return false;
  if (!user) return false;
  if (user.id === me.userId) return false;
  return canEdit(user, me, editable);
};

const userMetaInfo = (department?: Department, designation?: Designation) => {
  if (department && designation) {
    return `${designation.name} (${department.name})`;
  }
  if (department) {
    return department.name;
  }
  if (designation) {
    return designation.name;
  }
  return "";
};

export interface UserDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  userId: string | null;
}

export const UserDetailsDialog = (prop: UserDetailsDialogProps) => {
  const [edit, setEdit] = React.useState<UserPatchParam | null>(null);
  const [user, setUser] = React.useState<UserDetails | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleEditPress = () => {
    setEdit({
      firstName: user?.firstName,
      lastName: user?.lastName,
      managerId: user?.manager,
      departmentId: user?.department?.departmentId,
      designationId: user?.designation?.designationId,
      locationId: user?.location?.id,
    });
  };

  const loadUserDetails = React.useCallback(
    (userId?: string) => {
      setUser(null);
      if (!prop.open || !userId) return;
      setLoading(true);
      explisoftService
        .getUserDetails(userId, true, true)
        .then((res) => {
          setUser(res);
        })
        .catch((err) => {
          error(
            err.message || "Something went wrong while fetching user details"
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [prop]
  );

  const changeUserActiveState = (isActive: boolean) => {
    if (!user) return;
    const response = window.confirm(
      `Are you sure you want to ${isActive ? "enable" : "disable"} this user: ${
        user.userName
      }?`
    );
    if (!response) return;
    setLoading(true);
    explisoftService
      .changeUserActiveState(user.id, isActive)
      .then((res) => {
        success(res.message || "User disabled successfully");
        loadUserDetails(user.id);
      })
      .catch((err) => {
        error(err.message || "Something went wrong while disabling user");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDisableUser = () => {
    changeUserActiveState(false);
  };

  const handleEnableUser = () => {
    changeUserActiveState(true);
  };

  React.useEffect(() => {
    loadUserDetails(prop.userId);
    setEdit(null);
  }, [prop.userId, loadUserDetails]);

  const { value: me } = useUserDetails<MyInfo>(Key.USER_INFO);
  const { isAllowedUpdateUserInOrganization } = usePermission();
  const canEditUser = canEdit(user, me, isAllowedUpdateUserInOrganization);
  const canDisableUser = canDisable(
    user,
    me,
    isAllowedUpdateUserInOrganization
  );

  const handleUserSwitch = (userId: string) => {
    if (!userId) return;
    loadUserDetails(userId);
    setEdit(null);
  };

  const handleEdit = (prop: { [key: string]: any }) => {
    setEdit((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        ...prop,
      };
    });
  };

  const handleDepartmentChange = React.useCallback(
    (department: Department & { id: string }) => {
      handleEdit({
        departmentId: department?.departmentId,
      });
    },
    []
  );

  const handleDesignationChange = React.useCallback(
    (designation: Designation & { id: string }) => {
      handleEdit({
        designationId: designation?.designationId,
      });
    },
    []
  );

  const handleLocationChange = React.useCallback(
    (location: Location & { id: string }) => {
      handleEdit({
        locationId: location?.id,
      });
    },
    []
  );

  const handleManagerChange = React.useCallback((manager: UserDetails) => {
    handleEdit({
      managerId: manager?.id,
    });
  }, []);

  const handleFirstNameChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleEdit({
        firstName: e.target.value,
      });
    },
    []
  );

  const handleLastNameChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleEdit({
        lastName: e.target.value,
      });
    },
    []
  );

  const handleSaveUser = () => {
    setLoading(true);
    if (!edit) return;
    if (!user) return;
    const patchParam = {
      firstName: edit.firstName === user.firstName ? undefined : edit.firstName,
      lastName: edit.lastName === user.lastName ? undefined : edit.lastName,
      managerId: edit.managerId === user.manager ? undefined : edit.managerId,
      departmentId:
        edit.departmentId === user.department?.departmentId
          ? undefined
          : edit.departmentId,
      designationId:
        edit.designationId === user.designation?.designationId
          ? undefined
          : edit.designationId,
      locationId:
        edit.locationId === user.location?.id ? undefined : edit.locationId,
    };
    explisoftService
      .updateUserDetails(user.id, patchParam)
      .then((res) => {
        success(res.message || "User details updated successfully");
        setEdit(null);
        loadUserDetails(user.id);
      })
      .catch((err) => {
        error(
          err.message || "Something went wrong while updating user details"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      fullWidth
      maxWidth={edit ? "sm" : "xs"}
      open={prop.open}
      onClose={prop.onClose}
    >
      {user && (
        <>
          <DialogActions sx={{ marginBottom: "0px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: "10px",
              }}
            >
              <div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Avatar
                    large
                    firstName={user.firstName}
                    lastName={user.lastName}
                  />
                  <div style={{ alignSelf: "center", marginLeft: "15px" }}>
                    {!edit && (
                      <>
                        <Typography variant="h3">
                          {user.firstName} {user.lastName}
                        </Typography>
                        <Typography>
                          {userMetaInfo(user.department, user.designation)}
                        </Typography>
                        <Typography>
                          {user.location?.name} {user.location?.city}
                        </Typography>
                      </>
                    )}
                    {edit && (
                      <>
                        <TextField
                          label="First Name"
                          variant="outlined"
                          value={edit.firstName}
                          style={{ marginRight: "3px" }}
                          onChange={handleFirstNameChange}
                        />
                        <TextField
                          label="Last Name"
                          variant="outlined"
                          value={edit.lastName}
                          onChange={handleLastNameChange}
                        />
                        <DepartmentPicker
                          initalValue={
                            user.department
                              ? {
                                  ...user.department,
                                  id: user.department?.departmentId,
                                }
                              : undefined
                          }
                          id={"edit-user-department-picker"}
                          label="Select user's department"
                          style={{ marginTop: "10px" }}
                          onSelect={handleDepartmentChange}
                        />
                        <DesignationPicker
                          initalValue={
                            user.designation
                              ? {
                                  ...user.designation,
                                  id: user.designation?.designationId,
                                }
                              : undefined
                          }
                          id={"edit-user-designation-picker"}
                          label="Select user's designation"
                          style={{ marginTop: "10px" }}
                          onSelect={handleDesignationChange}
                        />
                        <LocationPicker
                          initalValue={
                            user.location
                              ? { ...user.location, id: user.location?.id }
                              : undefined
                          }
                          label="Select Location"
                          id="edit-user-location-picker"
                          style={{ marginTop: "10px" }}
                          onSelect={handleLocationChange}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div>
                {canDisableUser &&
                  !edit &&
                  (user.isActive ? (
                    <Tooltip title="Disable user">
                      <IconButton onClick={handleDisableUser}>
                        <IconUserX />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Enable user">
                      <IconButton onClick={handleEnableUser}>
                        <IconUserCheck />
                      </IconButton>
                    </Tooltip>
                  ))}
                {canEditUser && !edit && (
                  <IconButton onClick={handleEditPress}>
                    <IconEdit />
                  </IconButton>
                )}
                <IconButton onClick={prop.onClose}>
                  <IconX />
                </IconButton>
              </div>
            </div>
          </DialogActions>
          <DialogContent sx={{ paddingTop: "0px" }}>
            <div>
              <IconButton>
                <IconMessageCircle />
              </IconButton>
              <IconButton>
                <IconUsers />
              </IconButton>
              {user.isOwner && (
                <Tooltip title="Admin">
                  <IconButton>
                    <IconUserExclamation />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <hr />
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconMail />

                <Link
                  href={`mailto: ${user.emailId}`}
                  sx={{ marginLeft: "5px" }}
                >
                  {user.emailId}
                </Link>
              </div>
            </div>
            {(user.managerDetails || edit) && (
              <>
                <hr />
                <Typography sx={{ marginTop: "10px" }} variant="h5">
                  Reports to:
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  {!edit && (
                    <>
                      <Avatar
                        firstName={user.managerDetails.firstName}
                        lastName={user.managerDetails.lastName}
                        onClick={() => handleUserSwitch(user.managerDetails.id)}
                      />
                      <div style={{ alignSelf: "center", marginLeft: "15px" }}>
                        <Typography>
                          {user.managerDetails.firstName}{" "}
                          {user.managerDetails.lastName}
                        </Typography>
                        <Typography>
                          {userMetaInfo(
                            user.managerDetails.department,
                            user.managerDetails.designation
                          )}
                        </Typography>
                      </div>
                    </>
                  )}
                  {edit && (
                    <PeoplePicker
                      id="edit-user-manager-picker"
                      label="Select user's manager"
                      style={{ width: "100%" }}
                      initalValue={user.managerDetails}
                      onSelect={handleManagerChange}
                    />
                  )}
                </div>
              </>
            )}
          </DialogContent>
          {edit && !loading && (
            <DialogActions sx={{ marginTop: "0px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  padding: "10px",
                }}
              >
                <Button onClick={() => setEdit(null)} variant="outlined">
                  <IconX /> Cancel
                </Button>
                <Button
                  onClick={handleSaveUser}
                  variant="contained"
                  style={{ marginLeft: "5px" }}
                >
                  <IconDeviceFloppy /> Save
                </Button>
              </div>
            </DialogActions>
          )}
        </>
      )}
      <Wait loading={loading} />
    </Dialog>
  );
};
