import { explisoftService, EmailValidationResult, RegisterUserRequest, ApiGenericResponse, MyInfo, cache, Key } from '@services';
import { UserLicense } from '@services';
import { getCookie, setCookie, removeCookie } from 'typescript-cookie';

const AUTH_TOKEN_COOKIE = 'x-auth-token';
const AUTH_TOKEN_COOKIE_EXPIRY = 'x-auth-token-expiry';
const FIVE_DAYS = 5 * 24 * 60 * 60 * 1000;

export interface Policy {
    isOwner: boolean;
    licenseAndScope: UserLicense;
    isPortalUser: boolean;
    isPortalAdmin: boolean;
}

export interface AuthController {
    isAuthenticated(): boolean;
    login(userName: string, password: string): Promise<boolean>;
    logout(): boolean;
    validateEmail(email: string): Promise<EmailValidationResult>;
    generateOTP(email: string): Promise<{ otpId: string }>;
    registerUser(registerUserRequest: RegisterUserRequest): Promise<ApiGenericResponse>;
    me(noCache?: boolean): Promise<MyInfo>;
    forgotPassword(username: string): Promise<{ message: string }>;
    resetPassword(username: string, token: string): Promise<{ message: string }>;
    forgotUsername(email: string): Promise<{ message: string }>;
}

export class AuthControllerImpl implements AuthController {
    constructor() {
        console.log('Intializing AuthControllerImpl...');
    }

    isAuthenticated(): boolean {
        const authToken = getCookie(AUTH_TOKEN_COOKIE);

        setTimeout(async () => {
            const authTokenExpiry = parseInt(getCookie(AUTH_TOKEN_COOKIE_EXPIRY) || '0');
            if (!authToken || !authTokenExpiry) {
                return;
            }

            const ttl = authTokenExpiry - new Date().getTime();
            if (ttl < FIVE_DAYS) {
                const token = await explisoftService.renewToken();
                this.saveTokenCookie(token.token, token.expiry);
            }
        }, 0);
        return !!authToken;
    }
    async login(userName: string, password: string): Promise<boolean> {
        const authToken = await explisoftService.login(userName, password);
        this.saveTokenCookie(authToken.token, authToken.expiry);
        return true;
    }

    private saveTokenCookie(authToken: string, expiry: Date) {
        setCookie(AUTH_TOKEN_COOKIE, authToken, { expires: 30, path: '/' });
        setCookie(AUTH_TOKEN_COOKIE_EXPIRY, expiry.getTime(), { expires: 30, path: '/' });
    }

    async validateEmail(email: string): Promise<EmailValidationResult> {
        return await explisoftService.validateEmail(email);
    }

    async generateOTP(email: string): Promise<{ otpId: string }> {
        return await explisoftService.generateOTP(email);
    }

    async registerUser(registerUserRequest: RegisterUserRequest): Promise<ApiGenericResponse> {
        return await explisoftService.registerUser(registerUserRequest);
    }

    async me(noCache: boolean = false): Promise<MyInfo> {
        if (!this.isAuthenticated()) {
            throw new Error('Not authenticated');
        }
        let myInfo: MyInfo;
        if (noCache) {
            myInfo = await explisoftService.me();
        } else {
            myInfo = cache.get(Key.USER_INFO);
            if (!myInfo) {
                myInfo = await explisoftService.me();
            }
        }
        cache.put(Key.USER_INFO, myInfo);
        cache.put<Policy>(Key.POLICY, {
            isOwner: !!myInfo.isOwner,
            licenseAndScope: myInfo.licenseAndScope,
            isPortalUser: !!myInfo.isPortalUser,
            isPortalAdmin: !!myInfo.isPortalAdmin
        });
        return myInfo;
    }

    async forgotPassword(username: string): Promise<{ message: string }> {
        return await explisoftService.forgotPassword(username);
    }

    async resetPassword(username: string, token: string): Promise<{ message: string }> {
        return await explisoftService.resetPassword(username, token);
    }

    async forgotUsername(email: string): Promise<{ message: string }> {
        return await explisoftService.forgotUsername(email);
    }

    logout(): boolean {
        removeCookie(AUTH_TOKEN_COOKIE, { path: '/' });
        removeCookie(AUTH_TOKEN_COOKIE_EXPIRY, { path: '/' });
        cache.flush();
        return true;
    }
}
