import React, { lazy } from 'react';
// project imports
import MainLayout from '../layout/MainLayout';
import { Loadable } from '@components';

const RegisterContainer = Loadable(lazy(() => import('../views/authentication/authentication/RegisterContainer')));
const ConfirmLogin = Loadable(lazy(() => import('../views/authentication/authentication/ConfirmLogin')));
const LogoutThenLogin = Loadable(lazy(() => import('../views/authentication/authentication/LogoutThenLogin')));
const ForgotPasswordContainer = Loadable(lazy(() => import('../views/authentication/authentication/ForgotPasswordContainer')));
const ForgotUserNameContainer = Loadable(lazy(() => import('../views/authentication/authentication/ForgotUserNameContainer')));
const ResetPasswordContainer = Loadable(lazy(() => import('../views/authentication/authentication/ResetPasswordContainer')));
const RegisterPublicDomainAdmin = Loadable(lazy(() => import('../views/authentication/authentication/RegisterPublicDomainAdminContainer')));
const SignupWithAuthGroup = Loadable(lazy(() => import('../views/authentication/authentication/SignupWithAuthGroupContainer')));

// ==============================|| MAIN ROUTING ||============================== //

const OtherAuthenticationRoutes = () => ({
    path: '/',
    element: <MainLayout complete={false} />,
    children: [
        {
            path: '/auth/logout-then-login',
            element: <LogoutThenLogin />
        },
        {
            path: '/auth/confirm-login',
            element: <ConfirmLogin />
        },
        {
            path: '/auth/register',
            element: <RegisterContainer />
        },
        {
            path: '/auth/reset-password',
            element: <ResetPasswordContainer />
        },
        {
            path: '/auth/forgot-password',
            element: <ForgotPasswordContainer />
        },
        {
            path: '/auth/forgot-username',
            element: <ForgotUserNameContainer />
        },
        {
            path: '/auth/register-public-domain-admin',
            element: <RegisterPublicDomainAdmin />
        },
        {
            path: '/auth/group-signup/:groupId',
            element: <SignupWithAuthGroup />
        }
    ]
});

export default OtherAuthenticationRoutes;
