export interface Cache {
    get<T>(key: Key): T;
    put<T>(key: Key, value: T): void;
    flush(): void;
    addListener(key: Key, cb: () => void): () => void;
}

export enum Key {
    USER_INFO = 'USER_INFO',
    POLICY = 'POLICY',
    TOAST = 'TOAST'
}
