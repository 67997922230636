import React from 'react';
import { IconButton, TextField, Toolbar, Typography, Dialog, DialogContent, Box } from '@mui/material';
import { IconDeviceFloppy, IconEdit, IconX } from '@tabler/icons';
import { Account, AccountRequest, AccountType } from '@services';
import { useAccountManagement } from 'src/hooks/use-account-management';

export const AddUpdateAccountDialog = (props: {
    type: AccountType;
    open: boolean;
    onClose: (account?: Account) => void;
    account?: Account;
}) => {
    const { createAccount, updateAccount } = useAccountManagement(props.type);
    const [edit, setEdit] = React.useState(false);
    const [account, setAccount] = React.useState<AccountRequest>({
        name: '',
        phone: ''
    });

    React.useEffect(() => {
        setAccount({
            name: props.account?.name || '',
            phone: props.account?.phone || '',
            address: props.account?.address || '',
            pincode: props.account?.pincode || '',
            emailId: props.account?.emailId || '',
            website: props.account?.website || '',
            city: props.account?.city || '',
            state: props.account?.state || '',
            gstinNo: props.account?.gstinNo || '',
            panNo: props.account?.panNo || '',
            tanNo: props.account?.tanNo || '',
            cinNo: props.account?.cinNo || '',
            notes: props.account?.notes || ''
        });
        setEdit(!props.account);
    }, [props]);

    const onChange = React.useCallback((key: keyof AccountRequest, value: string) => {
        setAccount((prev) => ({ ...prev, [key]: value }));
    }, []);

    const save = () => {
        if (props.account?.accountId) {
            updateAccount(props.account.accountId, account).then((updatedAccount) => {
                props.onClose(updatedAccount);
            });
        } else {
            createAccount(account).then((createdAccount) => {
                props.onClose(createdAccount);
            });
        }
    };

    return (
        <Dialog fullWidth maxWidth={edit ? 'sm' : 'xs'} open={props.open} onClose={() => props.onClose()}>
            <Toolbar>
                <h2>{props.type}</h2>
                <Typography style={{ flex: 1 }}></Typography>
                {!edit && props.account?.accountId && (
                    <IconButton>
                        <IconEdit onClick={() => setEdit(true)} />
                    </IconButton>
                )}
                {(edit || !props.account?.accountId) && (
                    <IconButton>
                        <IconDeviceFloppy onClick={save} />
                    </IconButton>
                )}
                <IconButton onClick={() => props.onClose()}>
                    <IconX />
                </IconButton>
            </Toolbar>
            <DialogContent>
                {!edit && (
                    <div>
                        <Typography variant="h3">{account.name}</Typography>
                        <br />
                        {account.address && <Typography variant="body2">Address: {account.address}</Typography>}
                        {account.city && <Typography variant="body2">City: {account.city}</Typography>}
                        {account.state && <Typography variant="body2">State: {account.state}</Typography>}
                        {account.pincode && <Typography variant="body2">Pin: {account.pincode}</Typography>}
                        <br />
                        {account.emailId && <Typography variant="body1">Email: {account.emailId}</Typography>}
                        {account.phone && <Typography variant="body1">Phone: {account.phone}</Typography>}
                        {account.website && <Typography variant="body1">Website: {account.website}</Typography>}
                        <br />
                        {account.gstinNo && <Typography variant="body2">GST: {account.gstinNo}</Typography>}
                        {account.panNo && <Typography variant="body2">PAN: {account.panNo}</Typography>}
                        {account.tanNo && <Typography variant="body2">TAN: {account.tanNo}</Typography>}
                        {account.cinNo && <Typography variant="body2">CIN: {account.cinNo}</Typography>}
                        {account.notes && <Typography variant="body2">Notes: {account.notes}</Typography>}
                    </div>
                )}
                {edit && (
                    <div>
                        <TextField
                            style={{ padding: '7px', marginTop: '0px' }}
                            value={account.name}
                            label="Name"
                            required
                            variant="outlined"
                            fullWidth
                            onChange={(e) => onChange('name', e.target.value)}
                        />
                        <TextField
                            style={{ padding: '7px' }}
                            value={account.address}
                            label="Address"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => onChange('address', e.target.value)}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <TextField
                                style={{ padding: '7px' }}
                                value={account.phone}
                                required
                                label="Phone"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => onChange('phone', e.target.value)}
                            />
                            <TextField
                                style={{ padding: '7px' }}
                                value={account.pincode}
                                label="Pin Code"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => onChange('pincode', e.target.value)}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <TextField
                                style={{ padding: '7px' }}
                                value={account.emailId}
                                label="Email"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => onChange('emailId', e.target.value)}
                            />
                            <TextField
                                style={{ padding: '7px' }}
                                value={account.website}
                                label="Website"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => onChange('website', e.target.value)}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <TextField
                                style={{ padding: '7px' }}
                                value={account.city}
                                label="City"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => onChange('city', e.target.value)}
                            />
                            <TextField
                                style={{ padding: '7px' }}
                                value={account.state}
                                label="State"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => onChange('state', e.target.value)}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <TextField
                                style={{ padding: '7px' }}
                                value={account.gstinNo}
                                label="GST No"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => onChange('gstinNo', e.target.value)}
                            />
                            <TextField
                                style={{ padding: '7px' }}
                                value={account.panNo}
                                label="PAN No"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => onChange('panNo', e.target.value)}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <TextField
                                style={{ padding: '7px' }}
                                value={account.tanNo}
                                label="TAN no"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => onChange('tanNo', e.target.value)}
                            />
                            <TextField
                                style={{ padding: '7px' }}
                                value={account.cinNo}
                                label="CIN No"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => onChange('cinNo', e.target.value)}
                            />
                        </Box>
                        <TextField
                            style={{ padding: '7px' }}
                            value={account.notes}
                            label="Notes"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            onChange={(e) => onChange('notes', e.target.value)}
                        />
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
};
