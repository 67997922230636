import { Department, explisoftService, error as errorToast } from '@services';
import React from 'react';

export const useDepartmentManagement = (includingInActive: boolean) => {
    const [departments, setDepartments] = React.useState<Department[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<string | null>(null);

    const refreshDepartments = React.useCallback(async () => {
        setLoading(true);
        return explisoftService
            .getDepartments(includingInActive)
            .then((departments) => {
                setDepartments(departments);
            })
            .catch((error) => {
                setError(error.message);
                errorToast(error.message || 'Something went wrong when fetching departments');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [includingInActive]);

    React.useEffect(() => {
        refreshDepartments();
    }, [includingInActive, refreshDepartments]);

    const addDepartment = async (department: string, description: string) => {
        setLoading(true);
        return explisoftService
            .createDepartment(department, description)
            .then(() => {
                refreshDepartments();
            })
            .catch((error) => {
                setError(error.message);
                errorToast(error.message || 'Something went wrong when adding department');
                throw error;
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const updateDepartment = async (department: Department) => {
        setLoading(true);
        return explisoftService
            .updateDepartment(department)
            .then(() => {
                refreshDepartments();
            })
            .catch((error) => {
                setError(error.message);
                errorToast(error.message || 'Something went wrong when updating department');
                throw error;
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const changeActiveState = React.useCallback(
        async (department: Department, active: boolean) => {
            setLoading(true);
            return explisoftService
                .changeDepartmentActiveState(department, active)
                .then(() => {
                    refreshDepartments();
                })
                .catch((error) => {
                    setError(error.message);
                    errorToast(error.message || 'Something went wrong when changing active state');
                    throw error;
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [refreshDepartments]
    );

    return {
        departments,
        loading,
        error,
        refreshDepartments,
        addDepartment,
        updateDepartment,
        changeActiveState
    };
};
