import { Key, MyInfo, cache } from '@services';

export * from './date-time-utils';
export * from './password-strength';
export * from './icons';

export const skuFormatter = (sku: any) => {
    return sku.toString().padStart(10, '0');
};

export const orderIdFormatter = (orderId: any) => {
    return cache.get<MyInfo>(Key.USER_INFO).businessId + orderId.toString().padStart(8, '0');
};

export const dateToYYYYMMDD = (date: any) => {
    return new Date(date).toDateString();
};

export const CONSTANTS = {
    EMAIL_SUBSCRIPTION: 'email_subscription'
};
