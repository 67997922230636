// assets
import * as icons from '@tabler/icons';
import AppJson from '../views/apps/list.json';

const iconsMap: { [key: string]: icons.TablerIcon } = {};
for (const [key, icon] of Object.entries(icons)) {
    iconsMap[key] = icon;
}

const applications = [];

// sort AppJson.apps by priority, 10 is the highest priority and 1 is the lowest, then sort by name, if priority is missing, set it to 1
AppJson.apps.sort((a, b) => {
    if (a.priority === undefined) {
        a.priority = 1;
    }
    if (b.priority === undefined) {
        b.priority = 1;
    }
    if (a.priority === b.priority) {
        return a.name.localeCompare(b.name);
    }
    return b.priority - a.priority;
});

for (const app of AppJson.apps) {
    applications.push({
        id: `app-${app.name}`,
        title: `${app.displayName}`,
        type: 'item',
        url: `/apps/${app.name}`,
        icon: iconsMap[`${app.icon}`],
        breadcrumbs: true
    });
}

const Apps = {
    id: 'applications',
    title: 'Applications',
    type: 'group',
    children: applications
};

export default Apps;
