import * as React from 'react';
import { explisoftService, User } from '@services';

export const useGetAuthorizationGroupUsers = (groupId: string | undefined, ready = true) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState('');
    const [users, setUsers] = React.useState<User[]>([]);

    const fetchUsers = React.useCallback(() => {
        if (!ready) return;
        setUsers([]);
        setError('');
        if (!groupId) return;
        setIsLoading(true);
        explisoftService
            .getAuthorizationGroupUsers(groupId)
            .then((users) => {
                setUsers(users);
                setError('');
            })
            .catch((err) => {
                setError(err.message || 'Unable to fetch Authorization Group users');
                setUsers([]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [groupId, ready]);

    React.useEffect(() => {
        fetchUsers();
    }, [groupId, ready, fetchUsers]);

    return { isLoading, error, users, retry: fetchUsers };
};

export const useUpdateUsersInAuthorizationGroup = (
    groupId: string | undefined,
    onAddSuccess?: (userId: string) => void,
    onRemoveSuccess?: (userId: string) => void
) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState('');

    const addUser = (userId: string) => {
        setError('');
        if (!groupId) return;
        setIsLoading(true);
        explisoftService
            .addUserInAuthorizationGroup(groupId, userId)
            .then(() => {
                if (onAddSuccess) onAddSuccess(userId);
            })
            .catch((err) => {
                setError(err.message || 'Unable to add user in Authorization Group');
            })
            .finally(() => {
                setIsLoading(false);
                setTimeout(() => {
                    setError('');
                }, 3000);
            });
    };

    const removeUser = (userId: string) => {
        setError('');
        if (!groupId) return;
        setIsLoading(true);
        explisoftService
            .removeUserFromAuthorizationGroup(groupId, userId)
            .then(() => {
                if (onRemoveSuccess) onRemoveSuccess(userId);
            })
            .catch((err) => {
                setError(err.message || 'Unable to remove user from Authorization Group');
            })
            .finally(() => {
                setIsLoading(false);
                setTimeout(() => {
                    setError('');
                }, 3000);
            });
    };

    return { isLoading, error, addUser, removeUser };
};
