import React from 'react';
import { explisoftService, error as errorToast, AccountType, AccountRequest, Account, Contact, ContactRequest } from '@services';

export const useContactManagement = (type: AccountType, accountId: string) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>('');
    const [contacts, setContacts] = React.useState<Contact[]>([]);

    const fetchContacts = React.useCallback(async () => {
        setIsLoading(true);
        setError('');
        return explisoftService
            .getContacts(type, accountId)
            .then((nextContacts) => {
                setContacts(nextContacts);
                return nextContacts;
            })
            .catch((err) => {
                setError(err.message || 'Something went wrong');
                errorToast(err.message || 'Something went wrong');
                throw err;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [type, accountId]);

    React.useEffect(() => {
        fetchContacts();
    }, [fetchContacts]);

    const createContact = async (contact: ContactRequest) => {
        setIsLoading(true);
        setError('');
        return explisoftService
            .createContact(type, accountId, contact)
            .then((contact_1) => {
                fetchContacts();
                return contact_1;
            })
            .catch((err) => {
                setError(err.message || 'Something went wrong');
                errorToast(err.message || 'Something went wrong');
                throw err;
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateContact = async (contactId: string, contact: ContactRequest) => {
        setIsLoading(true);
        setError('');
        return explisoftService
            .updateContact(type, accountId, contactId, contact)
            .then((contact_1) => {
                fetchContacts();
                return contact_1;
            })
            .catch((err) => {
                setError(err.message || 'Something went wrong');
                errorToast(err.message || 'Something went wrong');
                throw err;
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return {
        isLoading,
        error,
        contacts,
        createContact,
        updateContact
    };
};
