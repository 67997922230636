import React from 'react';
import { explisoftService, error as errorToast, AccountType, AccountRequest, Account } from '@services';

export const useAccountManagement = (type: AccountType, query?: string) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>('');
    const [hasMore, setHasMore] = React.useState<boolean>(true);
    const [accounts, setAccounts] = React.useState<Account[]>([]);
    const [page, setPage] = React.useState<number>(1);

    const fetchAccounts = React.useCallback(
        async (page = 1) => {
            if (query === undefined) {
                return;
            }
            setIsLoading(true);
            setError('');
            try {
                try {
                    const nextAccounts = await explisoftService.getAccounts(type, query || '', page);
                    setPage(page);
                    setHasMore(nextAccounts.length === 10);
                    if (page === 1) {
                        setAccounts(nextAccounts);
                    } else {
                        setAccounts((prevAccounts) => [...prevAccounts, ...nextAccounts]);
                    }
                    return nextAccounts;
                } catch (err) {
                    setError(err.message || 'Something went wrong');
                    errorToast(err.message || 'Something went wrong');
                    throw err;
                }
            } finally {
                setIsLoading(false);
            }
        },
        [query, type]
    );

    React.useEffect(() => {
        fetchAccounts(1);
    }, [fetchAccounts]);

    const loadMore = () => {
        if (hasMore) {
            fetchAccounts(page + 1);
        }
    };

    const createAccount = async (account: AccountRequest) => {
        setIsLoading(true);
        setError('');
        try {
            try {
                const account_1 = await explisoftService.createAccount(type, account);
                fetchAccounts(1);
                return account_1;
            } catch (err) {
                setError(err.message || 'Something went wrong');
                errorToast(err.message || 'Something went wrong');
                throw err;
            }
        } finally {
            setIsLoading(false);
        }
    };

    const updateAccount = async (accountId: string, account: AccountRequest) => {
        setIsLoading(true);
        setError('');
        return explisoftService
            .updateAccount(type, accountId, account)
            .then((updatedAccount) => {
                const index = accounts.findIndex((account) => account.accountId === accountId);
                if (index !== -1) {
                    accounts[index] = updatedAccount;
                    setAccounts([...accounts]);
                }
                return updatedAccount;
            })
            .catch((err) => {
                setError(err.message || 'Something went wrong');
                errorToast(err.message || 'Something went wrong');
                throw err;
            })
            .finally(() => setIsLoading(false));
    };

    return {
        isLoading,
        error,
        createAccount,
        loadMore,
        hasMore,
        accounts,
        updateAccount,
        fetchAccounts
    };
};
