import { Location, explisoftService, error as errorToast, LocationRequest, LocationUpdateRequest } from '@services';
import React from 'react';

export const useLocationManagement = (includingInActive: boolean) => {
    const [locations, setLocations] = React.useState<Location[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<string | null>(null);

    const refreshLocations = React.useCallback(async () => {
        setLoading(true);
        return explisoftService
            .getLocations(includingInActive)
            .then((departments) => {
                setLocations(departments);
            })
            .catch((error) => {
                setError(error.message);
                errorToast(error.message || 'Something went wrong when fetching locations');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [includingInActive]);

    React.useEffect(() => {
        refreshLocations();
    }, [includingInActive, refreshLocations]);

    const addLocation = async (location: LocationRequest) => {
        setLoading(true);
        return explisoftService
            .createLocation(location)
            .then(() => {
                refreshLocations();
            })
            .catch((error) => {
                setError(error.message);
                errorToast(error.message || 'Something went wrong when adding location');
                throw error;
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const updateLocation = async (id: string, location: LocationUpdateRequest) => {
        setLoading(true);
        return explisoftService
            .updateLocation(id, location)
            .then(() => {
                refreshLocations();
            })
            .catch((error) => {
                setError(error.message);
                errorToast(error.message || 'Something went wrong when updating location');
                throw error;
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const changeActiveState = async (id: string, isActive: boolean) => {
        setLoading(true);
        return explisoftService
            .changeLocationActiveState(id, isActive)
            .then(() => {
                refreshLocations();
            })
            .catch((error) => {
                setError(error.message);
                errorToast(error.message || 'Something went wrong when updating location');
                throw error;
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        locations,
        loading,
        error,
        addLocation,
        updateLocation,
        changeActiveState,
        refreshLocations
    };
};
