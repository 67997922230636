import React from 'react';
import { orderIdFormatter } from '@utils';
import { EMIInstance, Order } from '@services';
import { Link } from '@mui/material';
import { usePermission } from '@hooks';
import { ReceiveEMIPaymentDialog } from './receive-emi-payment-dialog';

export const OrderPrintForm = React.forwardRef((props: { order: Order; onRefresh: () => void }, ref: any) => {
    const { isManageEMIAllowed } = usePermission();
    const { order } = props;
    const address = [order?.location?.address, order?.location?.city, order?.location?.state, order?.location?.pin];
    const digitalAddress = [order?.location?.phone, order?.location?.email, order?.location?.website];
    const totoalAmount = React.useMemo(() => {
        if (!order) {
            return 0;
        }
        return order.items.reduce((total, item) => {
            return total + item.price * item.quantity;
        }, 0);
    }, [order]);

    const totalEmiAmount = React.useMemo(() => {
        if (!order || !order.emiDetails) {
            return 0;
        }
        return order.emiDetails.reduce((total, emi) => {
            return total + emi.emiAmount;
        }, 0);
    }, [order]);

    const totalEmiAmountPaid = React.useMemo(() => {
        if (!order || !order.emiDetails) {
            return 0;
        }
        return order.emiDetails.reduce((total, emi) => {
            return total + emi.emiAmountPaid;
        }, 0);
    }, [order]);

    const [selectedEmiInstance, setSelectedEmiInstance] = React.useState<EMIInstance | null>(null);
    const handleEMIPaymentReceive = React.useCallback((emi: EMIInstance) => {
        setSelectedEmiInstance(emi);
    }, []);
    const handlEMIPaymentDialogClose = React.useCallback(() => {
        setSelectedEmiInstance(null);
    }, []);
    const handleEMIPaymentReceiveSave = React.useCallback(() => {
        setSelectedEmiInstance(null);
        props.onRefresh();
    }, [props]);

    if (!order) {
        return null;
    }
    return (
        <div ref={ref} style={{ padding: '10px' }}>
            <div style={{ textAlign: 'center', width: '100%', borderStyle: 'solid' }}>
                <h1>{order.location.name}</h1>
                <div>{address.join(', ')}</div>
                <div>{digitalAddress.join(', ')}</div>
                <h4>GSTIN No: {order.location.gstinNo}</h4>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', borderStyle: 'solid' }}>
                <div style={{ flex: 1, borderRight: 'solid', padding: '5px' }}>
                    <h3>Invoice Details</h3>
                    <div>Invoice No: {orderIdFormatter(order.invoiceNumber)}</div>
                    <div>Invoice Date: {order.invoiceDate.toDateString()}</div>
                </div>
                <div style={{ flex: 1, padding: '5px' }}>
                    <h3>Customer Details</h3>
                    <div>{order.customer.name}</div>
                    <div>{order.customer.address}</div>
                    <div>
                        {order.customer.city} {order.customer.state}
                    </div>
                    <div>{order.customer.phone}</div>
                    <div>{order.customer.emailId}</div>
                    <div>{order.customer.gstinNo}</div>
                </div>
            </div>
            <div style={{ minHeight: '20rem', borderStyle: 'solid' }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', borderBottom: 'solid' }}>
                    <div style={{ padding: '3px', width: '5%', borderRight: 'dotted' }}>Sr. No.</div>
                    <div style={{ padding: '3px', width: '35%', borderRight: 'dotted' }}>Product</div>
                    <div style={{ padding: '3px', width: '6%', borderRight: 'dotted' }}>HSN / SAC</div>
                    <div style={{ padding: '3px', width: '5%', borderRight: 'dotted' }}>Qty</div>
                    <div style={{ padding: '3px', width: '12%', borderRight: 'dotted' }}>Rate</div>
                    <div style={{ padding: '3px', width: '12%', borderRight: 'dotted' }}>Taxable Value</div>
                    <div style={{ padding: '3px', width: '5%', borderRight: 'dotted' }}>GST Rate</div>
                    <div style={{ padding: '3px', width: '10%', borderRight: 'dotted' }}>Amount</div>
                    <div style={{ padding: '3px', width: '10%' }}>Total</div>
                </div>
                {order.items.map((item, index) => {
                    const itemPrice = item.price / (1 + item.item.gstRate / 100);
                    const taxableAmount = itemPrice * item.quantity;
                    const tax = taxableAmount * (item.item.gstRate / 100);
                    return (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                borderBottom: 'solid'
                            }}
                        >
                            <div style={{ padding: '3px', width: '5%', borderRight: 'dotted', whiteSpace: 'normal' }}>{index + 1}</div>
                            <div style={{ padding: '3px', width: '35%', borderRight: 'dotted', whiteSpace: 'normal' }}>
                                {item.item.name}
                            </div>
                            <div style={{ padding: '3px', width: '6%', borderRight: 'dotted', whiteSpace: 'normal' }}>
                                {item.item.hsnCode}
                            </div>
                            <div style={{ padding: '3px', width: '5%', borderRight: 'dotted', whiteSpace: 'normal' }}>{item.quantity}</div>
                            <div style={{ padding: '3px', width: '12%', borderRight: 'dotted', whiteSpace: 'normal' }}>
                                {itemPrice.toFixed(2)}
                            </div>
                            <div style={{ padding: '3px', width: '12%', borderRight: 'dotted', whiteSpace: 'normal' }}>
                                {taxableAmount.toFixed(2)}
                            </div>
                            <div style={{ padding: '3px', width: '5%', borderRight: 'dotted', whiteSpace: 'normal' }}>
                                {item.item.gstRate}
                            </div>
                            <div style={{ padding: '3px', width: '10%', borderRight: 'dotted', whiteSpace: 'normal' }}>
                                {tax.toFixed(2)}
                            </div>
                            <div style={{ padding: '3px', width: '10%', whiteSpace: 'normal' }}>{item.price * item.quantity}</div>
                        </div>
                    );
                })}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', borderStyle: 'solid', borderTop: '0px' }}>
                <div style={{ padding: '3px', width: '10%', borderRight: 'dotted' }}>Total</div>
                <div style={{ padding: '3px', width: '10%' }}>&#8377;{totoalAmount}/-</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', borderStyle: 'solid', borderTop: '0px' }}>
                <div style={{ padding: '3px', width: '10%', borderRight: 'dotted' }}>Discount</div>
                <div style={{ padding: '3px', width: '10%' }}>&#8377;{order.discount}/-</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', borderStyle: 'solid', borderTop: '0px' }}>
                <div style={{ padding: '3px', width: '10%', borderRight: 'dotted' }}>Net Amount</div>
                <div style={{ padding: '3px', width: '10%' }}>&#8377;{totoalAmount - order.discount}/-</div>
            </div>
            {order.emiDetails && order.emiDetails.length > 0 && (
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', borderStyle: 'solid', marginTop: '40px' }}>
                        <div style={{ padding: '3px', width: '25%', borderRight: 'dotted' }}>#</div>
                        <div style={{ padding: '3px', width: '25%', borderRight: 'dotted' }}>
                            <b>Amount</b>
                        </div>
                        <div style={{ padding: '3px', width: '25%', borderRight: 'dotted' }}>
                            <b>Due Date</b>
                        </div>
                        <div style={{ padding: '3px', width: '25%' }}>
                            <b>Paid Amount</b>
                        </div>
                    </div>
                    {order.emiDetails.map((emi, index) => {
                        return (
                            <div
                                key={index}
                                style={{ display: 'flex', flexDirection: 'row', width: '100%', borderStyle: 'solid', borderTop: '0px' }}
                            >
                                <div style={{ padding: '3px', width: '25%', borderRight: 'dotted' }}>{index + 1}</div>
                                <div style={{ padding: '3px', width: '25%', borderRight: 'dotted' }}>&#8377;{emi.emiAmount}/-</div>
                                <div style={{ padding: '3px', width: '25%', borderRight: 'dotted' }}>{emi.emiDate.toDateString()}</div>
                                <div style={{ padding: '3px', width: '25%' }}>
                                    {isManageEMIAllowed && emi.emiAmount > emi.emiAmountPaid ? (
                                        <Link component="button" onClick={() => handleEMIPaymentReceive(emi)}>
                                            &#8377;{emi.emiAmountPaid}
                                        </Link>
                                    ) : (
                                        <>&#8377;{emi.emiAmountPaid}</>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', borderStyle: 'solid', borderTop: '0px' }}>
                        <div style={{ padding: '3px', width: '50%', borderRight: 'dotted' }}>
                            <b>Down Payment: &#8377;{order.downPayment}</b>
                        </div>
                        <div style={{ padding: '3px', width: '50%' }}>
                            <b>
                                Total Paid EMI: &#8377;{totalEmiAmountPaid} out of &#8377;{totalEmiAmount}
                            </b>
                        </div>
                    </div>
                </div>
            )}
            {selectedEmiInstance && (
                <ReceiveEMIPaymentDialog
                    onSave={handleEMIPaymentReceiveSave}
                    emiInstance={selectedEmiInstance}
                    onClose={handlEMIPaymentDialogClose}
                />
            )}
        </div>
    );
});

OrderPrintForm.displayName = 'OrderPrintForm';
