import * as React from 'react';
import { explisoftService, Tenant } from '@services';
import { IBasePickerProps, BasePicker, Data } from '../base-picker';

const getLabel = (option: Tenant) =>
    `${option.businessName} ${option.businessName !== option.domain ? ' - ' + option.domain : ''} (${option.businessId})`;
const getData = (query?: string) =>
    explisoftService
        .searchTenant(query)
        .then((data) => data.map((d) => ({ ...d, id: d.domainId })))
        .catch(() => []);

type ITenantPickerProps = Pick<
    IBasePickerProps<Tenant & Data>,
    Exclude<keyof IBasePickerProps<Tenant & Data>, 'getLabel' | 'getData' | 'getObjectId'>
>;

export function TenantPicker({
    multiple = false,
    id = 'tenant-picker',
    key,
    style = {},
    onSelect,
    label,
    initalValue = []
}: ITenantPickerProps) {
    return (
        <BasePicker<Tenant & Data>
            multiple={multiple}
            id={id}
            key={key}
            style={style}
            onSelect={onSelect}
            label={label}
            initalValue={initalValue}
            getLabel={getLabel}
            getData={getData}
        />
    );
}
