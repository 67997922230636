import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery, Typography, Button } from '@mui/material';

// project imports
import { Breadcrumbs } from '@components';
import Header from './Header';
import Sidebar from './Sidebar';
import Customization from '../Customization';
import navigation from '../../menu-items';
import { collapsedDrawerWidth, drawerWidth } from '../../store/constant';
import { SET_MENU } from '../../store/actions';
import { IconRefreshAlert, IconLogout } from '@tabler/icons';
// assets
import { IconChevronRight } from '@tabler/icons';
import { cache, Key, MyInfo } from '@services';
import { ServiceContext } from '../../controllers';

import { ScaleLoader } from 'react-spinners';

// styles
/* @ts-ignore */
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography['mainContent'],
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - collapsedDrawerWidth),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = (prop: { complete: boolean }) => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    // Handle left drawer
    /* @ts-ignore */
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };
    const [loading, setLoading] = useState(true);
    const [errorInLoading, setErrorInLoading] = useState({ error: false, message: '' });
    const [errorCode, setErrorCode] = useState('');

    const { authController } = useContext(ServiceContext);
    const navigate = useNavigate();
    const [user, setUser] = useState<MyInfo | null>(null);

    useEffect(() => {
        cache.addListener(Key.USER_INFO, () => {
            setUser(cache.get<MyInfo>(Key.USER_INFO));
        });
    }, []);

    const reload = React.useCallback(() => {
        setLoading(true);
        authController
            ?.me(true)
            .then(() => {
                setLoading(false);
                setErrorInLoading({ error: false, message: '' });
                setErrorCode('');
            })
            .catch((err) => {
                setLoading(false);
                setErrorInLoading({ error: true, message: err.message || 'Unable to load page, please try again' });
                setErrorCode(err.errorCode?.errorCode || err.errorCode);
            });
    }, [authController]);

    const logout = () => {
        authController?.logout();
        if (prop.complete) navigate('/');
    };

    useEffect(() => {
        reload();
        if (matchDownMd) {
            dispatch({ type: SET_MENU, opened: false });
        }
    }, [matchDownMd, reload, dispatch]);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {loading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        minHeight: '100vh',
                        minWidth: '100%'
                    }}
                >
                    <ScaleLoader color="#36d7b7" />
                </div>
            )}
            {!loading && errorInLoading.error && prop.complete && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        minHeight: '100vh',
                        minWidth: '100%'
                    }}
                >
                    <div>
                        <Typography variant="h3" color="error" align="center">
                            {errorInLoading.message}
                        </Typography>

                        <Button variant="contained" endIcon={<IconRefreshAlert />} style={{ marginTop: 30 }} onClick={reload}>
                            Try Again
                        </Button>
                        {(errorCode === 'INACTIVE_TENANT' || errorCode === 'INACTIVE_USER' || errorCode === 'EXPIRED_TOKEN') && (
                            <Button
                                variant="outlined"
                                endIcon={<IconLogout />}
                                style={{ marginTop: 30, marginLeft: '20px' }}
                                onClick={logout}
                            >
                                Logout
                            </Button>
                        )}
                    </div>
                </div>
            )}
            {!loading && !errorInLoading.error && prop.complete && (
                <>
                    <CssBaseline />
                    {/* header */}
                    <AppBar
                        enableColorOnDark
                        position="fixed"
                        color="inherit"
                        elevation={0}
                        sx={{
                            bgcolor: theme.palette.background.default,
                            transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                        }}
                    >
                        <Toolbar>
                            <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                        </Toolbar>
                    </AppBar>

                    {/* drawer */}
                    <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

                    {/* main content */}
                    {/* @ts-ignore */}
                    <Main theme={theme} open={leftDrawerOpened}>
                        {/* breadcrumb */}
                        {/* @ts-ignore */}
                        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                        <Outlet />
                    </Main>
                    <Customization />
                </>
            )}
            {!loading && !prop.complete && (
                <div style={{ width: '100%' }}>
                    <Customization />
                    <div>
                        {user && (
                            <div style={{ display: 'flex', justifyContent: 'space-between', background: '#EAEFF4' }}>
                                <div>
                                    You are logged in as {user.firstName} {user.lastName} ({user.userName})
                                </div>
                                <div>
                                    <Typography sx={{ textDecoration: 'none', cursor: 'pointer' }} variant="subtitle1" onClick={logout}>
                                        Logout
                                    </Typography>
                                </div>
                            </div>
                        )}
                        <div>
                            <Outlet />
                        </div>
                    </div>
                </div>
            )}
        </Box>
    );
};

export default MainLayout;
