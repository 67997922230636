import { IconButton, Tooltip } from '@mui/material';
import { IconArrowLeft } from '@tabler/icons';
import React from 'react';

export const BackButton = () => {
    const handleBackButtonPress = () => {
        window.history.back();
    };
    return (
        <Tooltip title="Back">
            <IconButton onClick={handleBackButtonPress}>
                <IconArrowLeft />
            </IconButton>
        </Tooltip>
    );
};
