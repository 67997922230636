import dashboard from './dashboard';
import Apps from './apps';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, Apps]
};

export default menuItems;
