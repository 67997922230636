import { ExplisoftServiceImpl, execute } from "./explisoft-service";

import { Cache, Key } from "./cache.interface";
import { InMemoryCache } from "./InMemoryCache";
import { ExplisoftService } from "./explisoft-service.interface";
export type { Policy } from "../controllers/auth-controller";
export * from "./explisoft-service.interface";
const explisoftService: ExplisoftService = new ExplisoftServiceImpl();
const cache: Cache = new InMemoryCache();

export { explisoftService, cache, Key, execute };

export * from "./toast";
