import React from 'react';
import { BackButton, MainCard, Wait } from '@components';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import { useGetOrderDetailsDetails } from '@hooks';
import { OrderPrintForm } from './order-print-form';
import ReactToPrint from 'react-to-print';
import { usePDF } from 'react-to-pdf';
import { orderIdFormatter } from '@utils';

export const DisplayName = 'Order Details';

export const App = () => {
    const routeParams = useParams();
    const orderId = get(routeParams, 'orderId');
    const { isLoading, order, loadOrderDetails } = useGetOrderDetailsDetails(orderId);
    const { toPDF, targetRef } = usePDF({ filename: `${orderIdFormatter(order?.invoiceNumber || 'explisoft_invoice')}.pdf` });

    const handleRefresh = React.useCallback(() => {
        loadOrderDetails();
    }, [loadOrderDetails]);

    return (
        <MainCard title={DisplayName} secondary={<BackButton />}>
            <Wait loading={isLoading} />
            {order && (
                <div>
                    <button onClick={() => toPDF()}>Download PDF</button>
                    <ReactToPrint trigger={() => <button>Print this out!</button>} content={() => targetRef.current} />
                    <OrderPrintForm order={order} ref={targetRef} onRefresh={handleRefresh} />
                </div>
            )}
        </MainCard>
    );
};

export const params = ['orderId'];
export const name = 'orderDetails';
export const open = (orderId: string, queryParams?: {}) => {
    return `/sub-apps/orderDetails/orderId/${orderId}`;
};
