import { explisoftService, error as errorToast } from '@services';
import React from 'react';

export const ImageLoader = (props: { imageUrl: string, projectId: string, contentId: string, onLoad: () => void, onError: () => void }) => {
    const [imageUrl, setImageUrl] = React.useState<string>(props.imageUrl);

    const handleImageChange = React.useCallback((e) => {
        const file = e.target.files[0];
        if (file.size > 2 * 1024 * 1024) {
            errorToast('Image size should be less than 2MB');
            return;
        }
        explisoftService.uploadImage(props.projectId, props.contentId, file).then((data) => {
            setImageUrl(data.imageUrl);
        }).catch((err) => {
            errorToast(err.message || 'Something went wrong while uploading image');
        });
    }, [props.projectId, props.contentId]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
            <img onLoad={props.onLoad} onError={props.onError} src={imageUrl || 'http://app.explisoft.com/invalid-image-url'} alt="content" style={{ width: '213px', height: '120px' }} />
            <input type="file" onChange={handleImageChange} accept="image/jpeg, image/png, image/webp" />
        </div>
    )
}