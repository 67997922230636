import { Cache, Key } from './cache.interface';

export class InMemoryCache implements Cache {
    private _cache: { [key: string]: any };
    private listener: { [key: string]: ((value: any) => void)[] } = {};

    public constructor() {
        console.log('Initializing InMemoryCache...');
        this._cache = {};
    }

    public get<T>(key: Key): T {
        return this._cache[key] as T;
    }

    public put<T>(key: Key, value: T) {
        this._cache[key] = value;
        this.listener[key]?.forEach((cb) => cb(value));
    }

    public flush() {
        const keys = Object.keys(this._cache);
        this._cache = {};
        keys.forEach((key) => {
            this.listener[key]?.forEach((cb) => cb(undefined));
        });
    }

    public addListener(key: Key, cb: () => void) {
        if (!this.listener[key]) {
            this.listener[key] = [];
        }
        this.listener[key].push(cb);
        return () => {
            this.listener[key] = this.listener[key].filter((c) => c !== cb);
        };
    }
}
