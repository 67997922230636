import * as React from 'react';
import { explisoftService, Account, AccountType, error as errorToast } from '@services';
import { IBasePickerProps, BasePicker, Data } from '../base-picker';

const getLabel = (option: Account) =>
    `${option.name ?? ''}, ${option.address ?? ''}, ${option.city ?? ''}, ${option.state ?? ''}, ${option.phone ?? ''}, ${
        option.emailId ?? ''
    } `;
const getData = (query?: string) =>
    explisoftService
        .getAccounts(AccountType.CUSTOMER, query)
        .then((data) => data.map((d) => ({ ...d, id: d.accountId })))
        .catch((err) => {
            errorToast(err.message || 'Failed to load customers');
            return [];
        });

type ICustomerPickerProps = Pick<
    IBasePickerProps<Account & Data>,
    Exclude<keyof IBasePickerProps<Account & Data>, 'getLabel' | 'getData' | 'getObjectId'>
>;

export function CustomerPicker({
    multiple = false,
    id = 'supplier-picker',
    key,
    style = {},
    onSelect,
    label,
    initalValue = []
}: ICustomerPickerProps) {
    return (
        <BasePicker<Account & Data>
            multiple={multiple}
            id={id}
            key={key}
            style={style}
            onSelect={onSelect}
            label={label}
            initalValue={initalValue}
            getLabel={getLabel}
            getData={getData}
        />
    );
}
