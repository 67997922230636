import { error, explisoftService } from '@services';
import React from 'react';

export const useTenantVideoQuota = (tenantId: string) => {
    const [quota, setQuota] = React.useState<number>(0);

    React.useEffect(() => {
        getTenantVideoQuota(tenantId);
    }, [tenantId]);

    const getTenantVideoQuota = async (tenantId: string) => {
        explisoftService.getTenantVideoQuota(tenantId).then((quota) => {
            setQuota(quota.quota);
        }).catch(() => {
            error('Failed to fetch tenant video quota');
        });
    };

    const updateTenantVideoQuota = React.useCallback(async (quota: number) => {
        return explisoftService.addTenantVideoQuota(tenantId, quota).then(() => {
            setQuota(q => q + quota);
        }).catch(() => {
            error('Failed to update tenant video quota');
        });
    }, [tenantId]);

    return {
        quota,
        updateTenantVideoQuota,
    };
}