import * as React from 'react';
import { explisoftService, Location } from '@services';
import { IBasePickerProps, BasePicker } from '../base-picker';

const getLabel = (option: Location) => `${option.name} ${option.address || ''} ${option.city || ''} ${option.state || ''}`;
const getData = (query: string) =>
    explisoftService
        .getLocations(false)
        .then((locations) => {
            if (!query) {
                return locations;
            }
            return locations.filter((location) => getLabel(location).toLowerCase().includes(query.toLowerCase()));
        })
        .catch(() => []);
export type IPeoplePickerProps = Pick<
    IBasePickerProps<Location>,
    Exclude<keyof IBasePickerProps<Location>, 'getLabel' | 'getData' | 'getObjectId'>
>;

export const LocationPicker = ({ multiple = false, id = '', key, style = {}, onSelect, label, initalValue = [] }: IPeoplePickerProps) => {
    return (
        <BasePicker<Location>
            multiple={multiple}
            id={id}
            key={key}
            style={style}
            onSelect={onSelect}
            label={label}
            initalValue={initalValue}
            getLabel={getLabel}
            getData={getData}
        />
    );
};
