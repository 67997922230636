import React from 'react';
import { User } from '@services';
import { Box, IconButton } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import { IconCopy } from '@tabler/icons';
import { Avatar } from '../avatar';

export const UserCard = (props: { user: User; editable: boolean; onClick?: (user: User) => void }) => {
    const handleOnClick = React.useCallback(() => {
        props.onClick?.(props.user);
    }, [props]);

    const handleUserInfoCopy = React.useCallback(
        (event) => {
            navigator.clipboard.writeText(`${props.user.firstName} ${props.user.lastName} (${props.user.emailId})`);
            event.stopPropagation();
        },
        [props]
    );

    return (
        <Box border={1} borderRadius={1} margin={2} marginLeft={0} color={'#AAA'} boxShadow={1} width={230}>
            <Card onClick={handleOnClick} style={{ cursor: props.onClick ? 'pointer' : '' }}>
                <CardContent style={{ padding: '1rem' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Avatar
                                firstName={props.user.firstName}
                                lastName={props.user.lastName}
                                disabled={!props.user.isActive}
                            ></Avatar>

                            <div style={{ fontWeight: 'bold', wordWrap: 'break-word', marginLeft: '10px', alignSelf: 'center' }}>
                                {props.user.firstName} {props.user.lastName}
                            </div>
                        </div>

                        <IconButton onClick={handleUserInfoCopy}>
                            <IconCopy />
                        </IconButton>
                    </div>
                    <div style={{ wordWrap: 'break-word' }}>{props.user.emailId}</div>
                </CardContent>
            </Card>
        </Box>
    );
};
