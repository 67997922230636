import { useSelector } from 'react-redux';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from './routes';

// defaultTheme
import themes from './themes';

// project imports
import NavigationScroll from './layout/NavigationScroll';

import { ServiceContext, authController } from './controllers';

// ==============================|| APP ||============================== //

const App = () => {
    /* @ts-ignore */
    const customization = useSelector((state) => state.customization);

    return (
        /* @ts-ignore */
        <ServiceContext.Provider value={{ authController }}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
        </ServiceContext.Provider>
    );
};

export default App;
