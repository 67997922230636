import React, { useState } from 'react';
import { Tenant, explisoftService, error as errorToast } from '@services';

export const useUpdateTenant = (tenantId: string | undefined) => {
    const [currentError, setCurrentError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [tenant, setTenant] = useState<Tenant | null>(null);

    const fetchTenantDetails = React.useCallback(() => {
        setLoading(true);
        setCurrentError(null);
        setTenant(null);
        if (!tenantId) {
            setCurrentError('Tenant ID is required');
            setLoading(false);
            return;
        }
        explisoftService
            .getTenantDetails(tenantId)
            .then((tenant) => {
                setTenant(tenant);
                setLoading(false);
            })
            .catch((error) => {
                setCurrentError(error);
                setLoading(false);
                errorToast(error.message || 'Error fetching tenant details');
            });
    }, [tenantId]);

    const enableTenant = React.useCallback(() => {
        setLoading(true);
        setCurrentError(null);
        if (!tenantId) {
            setCurrentError('Tenant ID is required');
            setLoading(false);
            return;
        }
        explisoftService
            .enableTenant(tenantId)
            .then(() => {
                setLoading(false);
                fetchTenantDetails();
            })
            .catch((error) => {
                setCurrentError(error);
                setLoading(false);
                errorToast(error.message || 'Error enabling tenant');
            });
    }, [tenantId, fetchTenantDetails]);

    const disableTenant = React.useCallback(() => {
        setLoading(true);
        setCurrentError(null);
        if (!tenantId) {
            setCurrentError('Tenant ID is required');
            setLoading(false);
            return;
        }
        explisoftService
            .disableTenant(tenantId)
            .then(() => {
                setLoading(false);
                fetchTenantDetails();
            })
            .catch((error) => {
                setCurrentError(error);
                setLoading(false);
                errorToast(error.message || 'Error disabling tenant');
            });
    }, [tenantId, fetchTenantDetails]);

    React.useEffect(() => {
        if (tenantId) {
            fetchTenantDetails();
        }
    }, [tenantId, fetchTenantDetails]);

    return {
        enableTenant,
        disableTenant,
        currentError,
        loading,
        tenant
    };
};
