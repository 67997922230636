import { cache, Key } from '@services';
import React from 'react';

export const useUserDetails = <T>(key: Key) => {
    const [userDetails, setUserDetails] = React.useState<T>();

    React.useEffect(() => {
        const handleUserInfoChange = () => {
            setUserDetails(cache.get<T>(key));
        };
        cache.addListener(key, handleUserInfoChange);
        handleUserInfoChange();
    }, [key]);
    return { value: userDetails };
};
