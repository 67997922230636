import React from 'react';
import { explisoftService, AuthorizationGroupPublicDetails } from '@services';

export const useGetAuthorizationGroupPublicDetails = (groupId: string) => {
    const [details, setDetails] = React.useState<AuthorizationGroupPublicDetails | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>('');

    React.useEffect(() => {
        if (!groupId) return;
        setLoading(true);
        explisoftService
            .getAuthorizationGroupPublicDetails(groupId)
            .then((groupDetails) => {
                setDetails(groupDetails);
                setError('');
            })
            .catch((error) => {
                setError(error.message || 'Something went wrong');
                setDetails(null);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [groupId]);

    return {
        details,
        loading,
        error
    };
};
