// assets
import * as icons from '@tabler/icons';

const iconsMap: { [key: string]: icons.TablerIcon } = {};
for (const [key, icon] of Object.entries(icons)) {
    iconsMap[key] = icon;
}

export const getIcon = (name: string) => {
    return iconsMap[name] || iconsMap['IconQuestionMark'];
};
export default iconsMap;
