import React from 'react';
import { Avatar as MuiAvatar } from '@mui/material';
import * as icons from '@tabler/icons';

export interface AvatarProps {
    anchorRef?: React.MutableRefObject<any>;
    onClick?: () => void;
    src?: string;
    icon?: icons.TablerIcon;
    alt?: string;
    firstName?: string;
    lastName?: string;
    large?: boolean;
    disabled?: boolean;
}

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string, size: number, disabled?: boolean) {
    return {
        sx: {
            bgcolor: disabled ? '#999' : stringToColor(name),
            width: size,
            height: size
        },
        children: `${name.split(' ')[0][0].toUpperCase()}${name.split(' ')[1][0].toUpperCase()}`
    };
}

export const Avatar = (prop: AvatarProps) => {
    const size = prop.large ? 72 : 40;
    return (
        <MuiAvatar
            src={prop.src}
            alt={prop.alt}
            ref={prop.anchorRef}
            color="inherit"
            onClick={prop.onClick}
            sx={{ height: size, width: size, cursor: prop.onClick ? 'pointer' : 'default' }}
        >
            <MuiAvatar {...stringAvatar(`${prop.firstName} ${prop.lastName}`, size, prop.disabled)}></MuiAvatar>
        </MuiAvatar>
    );
};
