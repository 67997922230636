import React from 'react';
import { error as errorToast, explisoftService, Order, OrderBasic, OrderFilter } from '@services';

export const useGetOrderDetailsDetails = (orderId: string) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>('');
    const [order, setOrder] = React.useState<Order | null>();

    const loadOrderDetails = React.useCallback(() => {
        setIsLoading(true);
        setOrder(null);
        setError('');
        explisoftService
            .getOrder(orderId)
            .then((response) => {
                setOrder({
                    ...response,
                    invoiceDate: response.invoiceDate ? new Date(response.invoiceDate) : null,
                    emiDetails: response.emiDetails.map((emi) => ({
                        ...emi,
                        emiDate: new Date(emi.emiDate)
                    }))
                });
            })
            .catch((error) => {
                setError(error);
                errorToast(error.message || 'Unable to get order details');
                throw error;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [orderId]);

    React.useEffect(() => {
        loadOrderDetails();
    }, [orderId, loadOrderDetails]);

    return { isLoading, error, order, loadOrderDetails };
};

export const useSearchOrders = (filter: OrderFilter) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>('');
    const [orders, setOrders] = React.useState<OrderBasic[]>([]);
    const [hasMore, setHasMore] = React.useState<boolean>(false);
    const [page, setPage] = React.useState<number>(1);

    const searchOrders = React.useCallback((filter: OrderFilter, page: number) => {
        setIsLoading(true);
        setError('');
        explisoftService
            .searchOrders(filter, page)
            .then((response) => {
                if (page === 1) {
                    setOrders(response.data);
                } else {
                    setOrders((orders) => [...orders, ...response.data]);
                }
                setPage(page);

                setHasMore(response.hasMore);
            })
            .catch((error) => {
                setError(error);
                errorToast(error.message || 'Unable to search orders');
                throw error;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    React.useEffect(() => {
        searchOrders(filter, 1);
    }, [searchOrders, filter]);

    const loadMore = React.useCallback(() => {
        searchOrders(filter, page + 1);
    }, [filter, page, searchOrders]);

    return { isLoading, error, orders, hasMore, loadMore };
};

export const useEmiOverdue = (days: number) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>('');
    const [orders, setOrders] = React.useState<OrderBasic[]>([]);

    React.useEffect(() => {
        setIsLoading(true);
        setError('');
        explisoftService
            .emiOverdue(days)
            .then((response) => {
                setOrders(response.data);
            })
            .catch((error) => {
                setError(error);
                errorToast(error.message || 'Unable to get overdue orders');
                throw error;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [days]);

    return { isLoading, error, orders };
};
