import * as React from 'react';
import { ScaleLoader } from 'react-spinners';
const styleLoader = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    minWidth: '100%',
    marginBottom: 10
};

const Wait = (props: { loading?: boolean }) => {
    if (props.loading === false) {
        return null;
    }
    return (
        <div style={{ ...styleLoader }}>
            <ScaleLoader color="#36d7b7" />
        </div>
    );
};

export default Wait;
