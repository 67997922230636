import * as React from 'react';
import { explisoftService, ItemStock } from '@services';
import { IBasePickerProps, BasePicker, Data } from '../base-picker';
import { skuFormatter } from '@utils';

const getLabel = (option: ItemStock) => `${skuFormatter(option.sku)} ${option.name} ${option.hsnCode ? '(' + option.hsnCode + ')' : ''}`;
const getData = (query: string, locationId: string) =>
    explisoftService
        .getAllItemsStockByLocation(locationId, query, 0)
        .then(({ items }) => {
            return items.map((option) => ({ ...option, id: option.sku.toString() }));
        })
        .catch(() => []);

interface OtherInformation {
    index: string;
    onSelect: (selectedItems: ItemStock[] | ItemStock, index: string) => void;
}

export type IInventoryItemPickerProps = Pick<
    IBasePickerProps<ItemStock & Data>,
    Exclude<keyof IBasePickerProps<ItemStock & Data>, 'getLabel' | 'getData' | 'getObjectId' | 'onSelect'>
> & { locationId: string } & OtherInformation;

export const InventoryItemPicker = ({
    multiple = false,
    id = '',
    key,
    style = {},
    onSelect,
    label,
    initalValue = [],
    locationId,
    index
}: IInventoryItemPickerProps) => {
    const getDataForLocation = React.useCallback((query?: string) => getData(query || '', locationId), [locationId]);

    const handleOnSelect = React.useCallback(
        (selectedItems: ItemStock[] | ItemStock) => {
            onSelect(selectedItems, index);
        },
        [onSelect, index]
    );
    return (
        <BasePicker<ItemStock & Data>
            multiple={multiple}
            id={id}
            key={key}
            style={style}
            onSelect={handleOnSelect}
            label={label}
            initalValue={initalValue}
            getLabel={getLabel}
            getData={getDataForLocation}
        />
    );
};
