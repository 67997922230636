import React from 'react';
import { Card, CardContent, Typography, CardHeader, IconButton } from "@mui/material";
import { Project } from "@services";
import { Edit } from '@mui/icons-material';
import { DeleteWithConfirmation } from '../button/delete-with-confirm';

export const ProjectCard = (props: { project: Project, onEditPress?: (project: Project) => void, onPress?: (project: Project) => void, onDelete?: (project: Project) => void }) => {
    const handleEditPress = React.useCallback((event: { stopPropagation: () => void; }) => {
        event.stopPropagation?.();
        props.onEditPress?.(props.project);
    }, [props]);

    const handlePress = React.useCallback(() => {
        props.onPress?.(props.project);
    }, [props]);

    const handleDeleteProject = React.useCallback(() => {
        props.onDelete?.(props.project);
    }, [props]);

    return (
        <Card sx={{ width: 400, broder: 1, borderRadius: 1, backgroundColor: '#EEE', boxShadow: 1, margin: '5px', justifyContent: 'space-between' }} style={{ cursor: props.onPress ? 'pointer' : '' }} onClick={handlePress}>
            <CardHeader
                action={
                    <>
                        <DeleteWithConfirmation onConfirm={handleDeleteProject} />
                        <IconButton aria-label="settings" onClick={handleEditPress}>
                            <Edit />
                        </IconButton>
                    </>
                }
                title={props.project.name}
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {props.project.description}
                </Typography>
            </CardContent>
        </Card>
    );
}