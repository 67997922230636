import React from 'react';
import { BackButton, MainCard } from '@components';
import { useParams } from 'react-router-dom';
import { usePurchaseHistory } from '@hooks';
import { get } from 'lodash';
import { ReceiveSupply } from '@services';
import { Typography } from '@mui/material';
import { skuFormatter } from '@utils';

export const DisplayName = 'Purchase Receipt Details';

export const App = () => {
    const routeParams = useParams();
    const { getPurchaseReceipt } = usePurchaseHistory();
    const purchaseReceiptId = get(routeParams, 'purchaseReceiptId');
    const [purchaseReceipt, setPurchaseReceipt] = React.useState<ReceiveSupply>(null);
    React.useEffect(() => {
        getPurchaseReceipt(purchaseReceiptId).then((purchaseReceipt) => {
            setPurchaseReceipt(purchaseReceipt);
        });
    }, [purchaseReceiptId, getPurchaseReceipt]);
    const total = purchaseReceipt?.items?.reduce((acc, item) => acc + item.quantity * item.itemPrice, 0);

    return (
        <MainCard title={DisplayName} secondary={<BackButton />}>
            {purchaseReceipt && (
                <div>
                    <div>
                        <Typography variant="h4">
                            Location: {purchaseReceipt.location.name}, {purchaseReceipt.location.address}, {purchaseReceipt.location.city}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h4">
                            Supplier: {purchaseReceipt.supplier.name}, {purchaseReceipt.supplier.address}, {purchaseReceipt.supplier.city},
                            {purchaseReceipt.supplier.phone}, {purchaseReceipt.supplier.emailId}
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="h4">Invoice Date: {purchaseReceipt?.invoiceDate.toDateString()}</Typography>
                        <Typography variant="h4">Invoice Number: {purchaseReceipt.invoiceNumber}</Typography>
                    </div>

                    <hr />
                    <div>
                        <Typography variant="h5">Items:</Typography>
                        <br />
                        {purchaseReceipt.items.map((item, index) => (
                            <div key={item.item.itemId}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        ({index + 1}) #{skuFormatter(item.item.sku)}
                                    </div>
                                    <div>
                                        <b>{item.item.name}</b>, {item.item.description}, {item.item.brand} {item.item.manufacturer}
                                    </div>
                                    <div>
                                        &#8377;{item.itemPrice} x {item.quantity} = &#8377;{item.itemPrice * item.quantity}
                                    </div>
                                </div>
                            </div>
                        ))}
                        <hr />
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>Total Price: &#8377;{total}</div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                Discount: &#8377;{purchaseReceipt.discountAmount}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                Net Price: <b>&#8377;{total - purchaseReceipt.discountAmount}</b>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </MainCard>
    );
};

// route will be generated like /sub-apps/<app-name>/params[0]/:params[0]/params[1]/:params[1]/params[2]/:params[2]
// so changing the params array or order of items in array will change the route
export const params = ['purchaseReceiptId'];
export const name = 'purchaseReceiptDetails';
export const open = (purchaseReceiptId: string, queryParams?: {}) => {
    return `/sub-apps/${name}/purchaseReceiptId/${purchaseReceiptId}`;
};
